// Player preferences

import { fetchFromLocalStorageCache, saveIntoLocalStorage } from "@/utils/local-storage";
import { AppEvents } from "./app-events";

const LS_KEY_PLAYER_VOLUME = "app-pref-player-volume";

/**
 * Event triggered when the player volume changes
 */
export const EVENT_NAME_PLAYER_VOLUME_CHANGED = "player-volume-changed";

/**
 * Gets player volume
 * @returns The volume
 */
export function getVolume(): number {
	return fetchFromLocalStorageCache(LS_KEY_PLAYER_VOLUME, 1);
}

/**
 * Sets player volume
 * @param volume The volume
 */
export function setVolume(volume: number) {
    saveIntoLocalStorage(LS_KEY_PLAYER_VOLUME, volume);
    AppEvents.Emit(EVENT_NAME_PLAYER_VOLUME_CHANGED, volume);
}

const LS_KEY_PLAYER_MUTED = "app-pref-player-muted";

/**
 * Event triggered when the player muted status changes
 */
export const EVENT_NAME_PLAYER_MUTED_CHANGED = "player-muted-changed";

/**
 * Gets player muted status
 * @returns The status
 */
export function getMuted(): boolean {
	return fetchFromLocalStorageCache(LS_KEY_PLAYER_MUTED, false);
}

/**
 * Sets player muted
 * @param status The muted status
 */
export function setMuted(status: boolean) {
    saveIntoLocalStorage(LS_KEY_PLAYER_MUTED, status);
    AppEvents.Emit(EVENT_NAME_PLAYER_MUTED_CHANGED, status);
}

const LS_KEY_PLAYER_DELAY = "app-pref-player-delay";

/**
 * Event triggered when the player delay changes
 */
export const EVENT_NAME_PLAYER_DELAY_CHANGED = "player-delay-changed";

/**
 * Gets player delay
 * @returns The delay
 */
export function getDelay(): number {
	return fetchFromLocalStorageCache(LS_KEY_PLAYER_DELAY, 30);
}

/**
 * Sets player delay
 * @param delay The delay
 */
export function setDelay(delay: number) {
    saveIntoLocalStorage(LS_KEY_PLAYER_DELAY, delay);
    AppEvents.Emit(EVENT_NAME_PLAYER_DELAY_CHANGED, delay);
}

const LS_KEY_PLAYER_RESOLUTION_H = "app-pref-player-resolution-h";

/**
 * Gets player resolution
 * @returns The resolution
 */
export function getResolution() {
    return fetchFromLocalStorageCache(LS_KEY_PLAYER_RESOLUTION_H, 0);
}

export function getBestResolution(resolutions) {
    if (resolutions.length === 0) {
        return 'unk';
    }
    const prefH = fetchFromLocalStorageCache(LS_KEY_PLAYER_RESOLUTION_H, 0);
    if (prefH) {
        let h = resolutions[0].width * resolutions[0].height * resolutions[0].fps;
        let selected = resolutions[0].name;
        for (let i = 1; i < resolutions.length; i++) {
            const ch = resolutions[i].width + resolutions[i].height * resolutions[i].fps;

            if (Math.abs(prefH - h) > Math.abs(prefH - ch)) {
                h = ch;
                selected = resolutions[i].name;
            }
        }
        return selected;
    } else {
        // Check screen size
        const width = window.innerWidth;
        const height = window.innerHeight;
        for (let j = 0; j < resolutions.length; j++) {
            if (resolutions[j].width <= width && resolutions[j].height <= height) {
                return resolutions[j].name;
            }
        }
        return resolutions[resolutions.length - 1].name;
    }
}

export function setChosenResolution(res) {
    let res_h = fetchFromLocalStorageCache(LS_KEY_PLAYER_RESOLUTION_H, 0)

    if (res === "best") {
        res_h = 0;
    } else {
        res_h = res.width * res.height * res.fps;
    }

    saveIntoLocalStorage(LS_KEY_PLAYER_RESOLUTION_H, res_h);
}



