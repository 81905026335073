<template>
    <div class="top-bar" tabindex="-1" :class="{ mini: !expanded }">
        <SearchBar @input="onInput" @search="onSearch" v-model:textplaceholder="placeholderText" />
        <button class="create-button btn btn-primary" @click="showCreateRoom"> 
            <i v-if="sidebarExpanded" class="fa-solid fa-plus"></i>
            <span v-if="sidebarExpanded"> {{ $t("Create room") }}</span> 
            <img v-else src="/svg/add-room.svg" alt="add-room-icon">
        </button>
    </div>
</template>

<script lang="ts">
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";
import SearchBar from '@/components/utils/SearchBar.vue';

export default defineComponent({
    components: {
        SearchBar,
    },
    name: "TopBar",
    emits: ["update:expanded", "toggle-menu", "openModal"],
    props: {
        expanded: Boolean,
    },
    setup(props) {
        return {
            expandedStatus: useVModel(props, "expanded"),
            resizeObserver: null as ResizeObserver,
        };
    },
    data: function () {
        return {
            platformName: import.meta.env.VITE__PLATFORM_NAME || "Platform",
            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            profileName: AuthController.ProfileName || AuthController.Username || "",
            placeholderText: "Search in Maya Interactivo...", 
            displayCreateRoom: false,
            sidebarExpanded: window.innerWidth > 992,
        };
    },
    methods: {
        openUserSettings: function () {
            this.$emit("openModal", "account-settings");
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.Username || "";
            this.profileImage = AuthController.ProfileImage;
        },

        renderName: function (name: string): string {
            return ((name + "").split(" ")[0] + "").split(",")[0] || "???";
        },

        //Search bar
        onInput(query) {
            console.log('Input event:', query);
        },
        onSearch(query) {
            console.log('Search event:', query);
        },
        showCreateRoom: function () {
            this.$emit("openModal", "create-room");
        },
        onPageResize: function () {
            this.sidebarExpanded = window.innerWidth > 992;
        },

    },
    mounted: function () {
        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        if (window.ResizeObserver) {
            this.resizeObserver = new ResizeObserver(this.onPageResize.bind(this));
            this.resizeObserver.observe(this.$el);
        }
    },
    beforeUnmount: function () { 
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
});
</script>

<style scoped>
@import "@/style/layout/top-bar.css";


</style>
