// API bindings: auth (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler, CommonErrorHandler } from "@asanrom/request-browser";
import { getApiUrl } from "./utils";
import { AuthenticationContext, LoginResponse, LoginRequest, ThirdPartyLoginService, ThirdPartyLoginResponse, ThirdPartyLoginBody, SignupTPResponse, SignupTPRequest, TFALoginRequest, SignupResponse, SignupRequest, EmailVerifyResponse, EmailVerifyRequest, ForgotPasswordResponse, ForgotPasswordRequest, ResetPasswordRequest, LoginAnonResponse, LoginAnonRequest } from "./definitions";

export class ApiAuth {
    /**
     * Method: GET
     * Path: /auth/context
     * Authentication context
     * @returns The request parameters
     */
    public static GetContext(): RequestParams<AuthenticationContext, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/auth/context`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /auth/login
     * Login with username and password
     * @param body Body parameters
     * @returns The request parameters
     */
    public static Login(body: LoginRequest): RequestParams<LoginResponse, LoginErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/auth/login`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "USER_BANNED", handler.forbiddenUserBanned)
                    .add(403, "INVALID_CREDENTIALS", handler.forbiddenInvalidCredentials)
                    .add(403, "CAPTCHA", handler.forbiddenCaptcha)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_CREDENTIALS", handler.badRequestInvalidCredentials)
                    .add(400, "CAPTCHA", handler.badRequestCaptcha)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /auth/tp
     * Get Third party login information
     * @returns The request parameters
     */
    public static ThirdPartyLoginDetails(): RequestParams<ThirdPartyLoginService[], CommonErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/auth/tp`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /auth/login/tp
     * Login with a third party service
     * @param body Body parameters
     * @returns The request parameters
     */
    public static ThirdPartyLogin(body: ThirdPartyLoginBody): RequestParams<ThirdPartyLoginResponse, ThirdPartyLoginErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/auth/login/tp`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "BANNED", handler.badRequestBanned)
                    .add(400, "OAUTH_ERROR", handler.badRequestOauthError)
                    .add(400, "NO_CODE", handler.badRequestNoCode)
                    .add(400, "SERVICE_INVALID", handler.badRequestServiceInvalid)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /auth/signup/tp
     * Creates an account using a third party user provider
     * @param body Body parameters
     * @returns The request parameters
     */
    public static ThirdPartyRegister(body: SignupTPRequest): RequestParams<SignupTPResponse, ThirdPartyRegisterErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/auth/signup/tp`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "WEAK_PASSWORD", handler.badRequestWeakPassword)
                    .add(400, "EMAIL_IN_USE", handler.badRequestEmailInUse)
                    .add(400, "USERNAME_IN_USE", handler.badRequestUsernameInUse)
                    .add(400, "USERNAME_INVALID", handler.badRequestUsernameInvalid)
                    .add(400, "TOKEN_INVALID", handler.badRequestTokenInvalid)
                    .add(400, "ID_INVALID", handler.badRequestIdInvalid)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /auth/tfa
     * Two factor authentication login. Input the one-use code
     * @param body Body parameters
     * @returns The request parameters
     */
    public static LoginTFA(body: TFALoginRequest): RequestParams<void, LoginTFAErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/auth/tfa`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "INVALID_CODE", handler.forbiddenInvalidCode)
                    .add(403, "*", handler.forbidden)
                    .add(400, "CAPTCHA", handler.badRequestCaptcha)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /auth/logout
     * Logout
     * @returns The request parameters
     */
    public static Logout(): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/auth/logout`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /auth/signup
     * Creates an account using email + password
     * @param body Body parameters
     * @returns The request parameters
     */
    public static Signup(body: SignupRequest): RequestParams<SignupResponse, SignupErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/auth/signup`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "WEAK_PASSWORD", handler.badRequestWeakPassword)
                    .add(400, "USERNAME_IN_USE", handler.badRequestUsernameInUse)
                    .add(400, "USERNAME_INVALID", handler.badRequestUsernameInvalid)
                    .add(400, "EMAIL_IN_USE", handler.badRequestEmailInUse)
                    .add(400, "EMAIL_INVALID", handler.badRequestEmailInvalid)
                    .add(400, "CAPTCHA", handler.badRequestCaptcha)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /auth/email/verify
     * Sends request to verify an account
     * @param body Body parameters
     * @returns The request parameters
     */
    public static VerifyEmail(body: EmailVerifyRequest): RequestParams<EmailVerifyResponse, VerifyEmailErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/auth/email/verify`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "EMAIL_IN_USE", handler.forbiddenEmailInUse)
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /auth/password/forgot
     * Request password reset email.
     * Note: if the account is not verified, a verification email will be send instead
     * @param body Body parameters
     * @returns The request parameters
     */
    public static RequestPasswordReset(body: ForgotPasswordRequest): RequestParams<ForgotPasswordResponse, RequestPasswordResetErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/auth/password/forgot`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "EMAIL_INVALID", handler.badRequestEmailInvalid)
                    .add(400, "CAPTCHA", handler.badRequestCaptcha)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /auth/password/reset
     * Resets account password
     * @param body Body parameters
     * @returns The request parameters
     */
    public static ResetAccountPassword(body: ResetPasswordRequest): RequestParams<void, ResetAccountPasswordErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/auth/password/reset`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "WEAK_PASSWORD", handler.badRequestWeakPassword)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /auth/anonymous
     * Get token to join with anonymous user
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostAuthAnonymous(body: LoginAnonRequest): RequestParams<LoginAnonResponse, PostAuthAnonymousErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/auth/anonymous`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "BLOCKED", handler.forbiddenBlocked)
                    .add(403, "COOLDOWN", handler.forbiddenCooldown)
                    .add(403, "*", handler.forbidden)
                    .add(400, "CAPTCHA", handler.badRequestCaptcha)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Error handler for Login
 */
export type LoginErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = CAPTCHA
     */
    badRequestCaptcha: () => void;

    /**
     * Handler for status = 400 and code = INVALID_CREDENTIALS
     */
    badRequestInvalidCredentials: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * Handler for status = 403 and code = CAPTCHA
     */
    forbiddenCaptcha: () => void;

    /**
     * Handler for status = 403 and code = INVALID_CREDENTIALS
     */
    forbiddenInvalidCredentials: () => void;

    /**
     * Handler for status = 403 and code = USER_BANNED
     */
    forbiddenUserBanned: () => void;
};

/**
 * Error handler for ThirdPartyLogin
 */
export type ThirdPartyLoginErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = SERVICE_INVALID
     */
    badRequestServiceInvalid: () => void;

    /**
     * Handler for status = 400 and code = NO_CODE
     */
    badRequestNoCode: () => void;

    /**
     * Handler for status = 400 and code = OAUTH_ERROR
     */
    badRequestOauthError: () => void;

    /**
     * Handler for status = 400 and code = BANNED
     */
    badRequestBanned: () => void;
};

/**
 * Error handler for ThirdPartyRegister
 */
export type ThirdPartyRegisterErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = ID_INVALID
     */
    badRequestIdInvalid: () => void;

    /**
     * Handler for status = 400 and code = TOKEN_INVALID
     */
    badRequestTokenInvalid: () => void;

    /**
     * Handler for status = 400 and code = USERNAME_INVALID
     */
    badRequestUsernameInvalid: () => void;

    /**
     * Handler for status = 400 and code = USERNAME_IN_USE
     */
    badRequestUsernameInUse: () => void;

    /**
     * Handler for status = 400 and code = EMAIL_IN_USE
     */
    badRequestEmailInUse: () => void;

    /**
     * Handler for status = 400 and code = WEAK_PASSWORD
     */
    badRequestWeakPassword: () => void;
};

/**
 * Error handler for LoginTFA
 */
export type LoginTFAErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = CAPTCHA
     */
    badRequestCaptcha: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * Handler for status = 403 and code = INVALID_CODE
     */
    forbiddenInvalidCode: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for Signup
 */
export type SignupErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = CAPTCHA
     */
    badRequestCaptcha: () => void;

    /**
     * Handler for status = 400 and code = EMAIL_INVALID
     */
    badRequestEmailInvalid: () => void;

    /**
     * Handler for status = 400 and code = EMAIL_IN_USE
     */
    badRequestEmailInUse: () => void;

    /**
     * Handler for status = 400 and code = USERNAME_INVALID
     */
    badRequestUsernameInvalid: () => void;

    /**
     * Handler for status = 400 and code = USERNAME_IN_USE
     */
    badRequestUsernameInUse: () => void;

    /**
     * Handler for status = 400 and code = WEAK_PASSWORD
     */
    badRequestWeakPassword: () => void;
};

/**
 * Error handler for VerifyEmail
 */
export type VerifyEmailErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * Handler for status = 403 and code = EMAIL_IN_USE
     */
    forbiddenEmailInUse: () => void;
};

/**
 * Error handler for RequestPasswordReset
 */
export type RequestPasswordResetErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = CAPTCHA
     */
    badRequestCaptcha: () => void;

    /**
     * Handler for status = 400 and code = EMAIL_INVALID
     */
    badRequestEmailInvalid: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for ResetAccountPassword
 */
export type ResetAccountPasswordErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = WEAK_PASSWORD
     */
    badRequestWeakPassword: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostAuthAnonymous
 */
export type PostAuthAnonymousErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = CAPTCHA
     */
    badRequestCaptcha: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * Handler for status = 403 and code = COOLDOWN
     */
    forbiddenCooldown: () => void;

    /**
     * Handler for status = 403 and code = BLOCKED
     */
    forbiddenBlocked: () => void;
};

