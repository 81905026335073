// API bindings: account (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { UsernameChangeRequest, EmailChangeRequest, PasswordChangeRequest, TFAGenResponse, TFASetupRequest, TFARemoveRequest, SessionListItem, ChangeLocaleBody, DeleteAccountRequest, RoomList, SetFavRoomRequest, FavouriteRoomsList, OrgInviteItem, AccountQuotaResponse, APITokenItem, CreateAPITokenResponse, CreateAPITokenRequest } from "./definitions";

export class ApiAccount {
    /**
     * Method: POST
     * Path: /account/username
     * Changes username
     * @param body Body parameters
     * @returns The request parameters
     */
    public static ChangeUsername(body: UsernameChangeRequest): RequestParams<void, ChangeUsernameErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/account/username`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "INVALID_TFA_CODE", handler.badRequestInvalidTfaCode)
                    .add(400, "USERNAME_IN_USE", handler.badRequestUsernameInUse)
                    .add(400, "USERNAME_INVALID", handler.badRequestUsernameInvalid)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /account/email
     * Changes email.
     * Warning: This just requests the change (sends email). The email must be validated.
     * @param body Body parameters
     * @returns The request parameters
     */
    public static ChangeEmail(body: EmailChangeRequest): RequestParams<void, ChangeEmailErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/account/email`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "INVALID_TFA_CODE", handler.badRequestInvalidTfaCode)
                    .add(400, "EMAIL_IN_USE", handler.badRequestEmailInUse)
                    .add(400, "EMAIL_INVALID", handler.badRequestEmailInvalid)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "CAPTCHA", handler.badRequestCaptcha)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /account/password
     * Changes password.
     * @param body Body parameters
     * @returns The request parameters
     */
    public static ChangePassword(body: PasswordChangeRequest): RequestParams<void, ChangePasswordErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/account/password`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "WEAK_PASSWORD", handler.badRequestWeakPassword)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /account/tfa/generate
     * Generates two factor authentication secret
     * @returns The request parameters
     */
    public static GenerateTFA(): RequestParams<TFAGenResponse, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/account/tfa/generate`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /account/tfa/setup
     * Setup two factor authentication
     * Warning: This closes all user sessions
     * @param body Body parameters
     * @returns The request parameters
     */
    public static SetupTFA(body: TFASetupRequest): RequestParams<void, SetupTFAErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/account/tfa/setup`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "INVALID", handler.badRequestInvalid)
                    .add(400, "ALREADY", handler.badRequestAlready)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /account/tfa/disable
     * Disables two factor authentication
     * @param body Body parameters
     * @returns The request parameters
     */
    public static RemoveTFA(body: TFARemoveRequest): RequestParams<void, RemoveTFAErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/account/tfa/disable`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "INVALID", handler.badRequestInvalid)
                    .add(400, "NOT_ENABLED", handler.badRequestNotEnabled)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /account/sessions
     * Gets all sessions open, so you can close them
     * This is a security feature
     * @returns The request parameters
     */
    public static GetSessions(): RequestParams<SessionListItem[], CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/account/sessions`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /account/sessions/{id}
     * Closes an active session
     * @param id Session ID
     * @returns The request parameters
     */
    public static CloseSession(id: string): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/account/sessions/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /account/sessions/close
     * Closes all user sessions
     * @returns The request parameters
     */
    public static CloseAllSessions(): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/account/sessions/close`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /account/locale
     * Changes user locale
     * The user locale is the language used for emails
     * @param body Body parameters
     * @returns The request parameters
     */
    public static ChangeLocale(body: ChangeLocaleBody): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/account/locale`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /account/delete
     * Deletes account
     * @param body Body parameters
     * @returns The request parameters
     */
    public static DeleteAccount(body: DeleteAccountRequest): RequestParams<void, DeleteAccountErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/account/delete`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "INVALID_TFA_CODE", handler.badRequestInvalidTfaCode)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /account/rooms
     * Gets list of rooms owned by the user
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetAccountRooms(queryParams: GetAccountRoomsQueryParameters): RequestParams<RoomList, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/account/rooms` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /account/rooms/fav
     * Sets a room as favorite
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostAccountRoomsFav(body: SetFavRoomRequest): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/account/rooms/fav`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /account/rooms/fav
     * Get user Favorite rooms
     * @returns The request parameters
     */
    public static GetAccountRoomsFav(): RequestParams<FavouriteRoomsList, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/account/rooms/fav`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /account/rooms/fav/{rid}
     * Removes a room from favorites
     * @param rid Room ID
     * @returns The request parameters
     */
    public static DeleteAccountRoomsFavRid(rid: string): RequestParams<void, DeleteAccountRoomsFavRidErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/account/rooms/fav/${encodeURIComponent(rid)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /account/organization/invites
     * Gets list of pending invites to organizations
     * @returns The request parameters
     */
    public static GetAccountOrganizationInvites(): RequestParams<OrgInviteItem[], CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/account/organization/invites`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /account/organization/invites/{id}
     * Accepts invite to organization
     * @param id Organization ID
     * @returns The request parameters
     */
    public static PostAccountOrganizationInvitesId(id: string): RequestParams<void, PostAccountOrganizationInvitesIdErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/account/organization/invites/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /account/organization/invites/{id}
     * Rejects invite to organization
     * @param id Organization ID
     * @returns The request parameters
     */
    public static DeleteAccountOrganizationInvitesId(id: string): RequestParams<void, DeleteAccountOrganizationInvitesIdErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/account/organization/invites/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /account/quota
     * Gets account quota information
     * @returns The request parameters
     */
    public static GetAccountQuota(): RequestParams<AccountQuotaResponse, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/account/quota`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /account/apitokens
     * Gets the list of API tokens for the account
     * @returns The request parameters
     */
    public static GetAccountApitokens(): RequestParams<APITokenItem[], GetAccountApitokensErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/account/apitokens`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /account/apitokens
     * Creates API token
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostAccountApitokens(body: CreateAPITokenRequest): RequestParams<CreateAPITokenResponse, PostAccountApitokensErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/account/apitokens`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "TOO_MANY", handler.badRequestTooMany)
                    .add(400, "INVALID_TFA_CODE", handler.badRequestInvalidTfaCode)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /account/apitokens/{id}
     * Deletes API token
     * @param id Token ID
     * @returns The request parameters
     */
    public static DeleteAccountApitokensId(id: string): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/account/apitokens/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Error handler for ChangeUsername
 */
export type ChangeUsernameErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = WRONG_PASSWORD
     */
    badRequestWrongPassword: () => void;

    /**
     * Handler for status = 400 and code = USERNAME_INVALID
     */
    badRequestUsernameInvalid: () => void;

    /**
     * Handler for status = 400 and code = USERNAME_IN_USE
     */
    badRequestUsernameInUse: () => void;

    /**
     * Handler for status = 400 and code = INVALID_TFA_CODE
     */
    badRequestInvalidTfaCode: () => void;
};

/**
 * Error handler for ChangeEmail
 */
export type ChangeEmailErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = CAPTCHA
     */
    badRequestCaptcha: () => void;

    /**
     * Handler for status = 400 and code = WRONG_PASSWORD
     */
    badRequestWrongPassword: () => void;

    /**
     * Handler for status = 400 and code = EMAIL_INVALID
     */
    badRequestEmailInvalid: () => void;

    /**
     * Handler for status = 400 and code = EMAIL_IN_USE
     */
    badRequestEmailInUse: () => void;

    /**
     * Handler for status = 400 and code = INVALID_TFA_CODE
     */
    badRequestInvalidTfaCode: () => void;
};

/**
 * Error handler for ChangePassword
 */
export type ChangePasswordErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = WRONG_PASSWORD
     */
    badRequestWrongPassword: () => void;

    /**
     * Handler for status = 400 and code = WEAK_PASSWORD
     */
    badRequestWeakPassword: () => void;
};

/**
 * Error handler for SetupTFA
 */
export type SetupTFAErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = WRONG_PASSWORD
     */
    badRequestWrongPassword: () => void;

    /**
     * Handler for status = 400 and code = ALREADY
     */
    badRequestAlready: () => void;

    /**
     * Handler for status = 400 and code = INVALID
     */
    badRequestInvalid: () => void;
};

/**
 * Error handler for RemoveTFA
 */
export type RemoveTFAErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = NOT_ENABLED
     */
    badRequestNotEnabled: () => void;

    /**
     * Handler for status = 400 and code = INVALID
     */
    badRequestInvalid: () => void;
};

/**
 * Error handler for DeleteAccount
 */
export type DeleteAccountErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = WRONG_PASSWORD
     */
    badRequestWrongPassword: () => void;

    /**
     * Handler for status = 400 and code = INVALID_TFA_CODE
     */
    badRequestInvalidTfaCode: () => void;
};

/**
 * Query parameters for GetAccountRooms
 */
export interface GetAccountRoomsQueryParameters {
    /**
     * Page. Starting by 1.
     */
    page?: number;
}

/**
 * Error handler for DeleteAccountRoomsFavRid
 */
export type DeleteAccountRoomsFavRidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostAccountOrganizationInvitesId
 */
export type PostAccountOrganizationInvitesIdErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for DeleteAccountOrganizationInvitesId
 */
export type DeleteAccountOrganizationInvitesIdErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetAccountApitokens
 */
export type GetAccountApitokensErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;
};

/**
 * Error handler for PostAccountApitokens
 */
export type PostAccountApitokensErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_TFA_CODE
     */
    badRequestInvalidTfaCode: () => void;

    /**
     * Handler for status = 400 and code = TOO_MANY
     */
    badRequestTooMany: () => void;
};

