<template>
    <div class="streamings-modals-container" :class="{'middle-mouse-event': moving}">
        <div v-for="streaming in streamings" :key="streaming.sid">
            <RoomSectionStreamingModal @hide-streaming="hideStreaming" @start-move="onStartMoving" @end-move="onEndMoving" :display="streaming.display" :room="streaming.room" :sid="streaming.sid" :sTitle="streaming.title"></RoomSectionStreamingModal>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RoomController } from '@/control/room';

import RoomSectionStreamingModal from "@/components/modals/streaming-modal/RoomSectionStreamingModal.vue";

export default defineComponent({
    components: {
        RoomSectionStreamingModal,
    },
    name: "StreamingModalMain",
    data: function () {
        return {
            room: "",
            streamings: [],
            moving: false,
        };
    },
    methods: {
        showStreaming: function(sid: string, room: string, title: string) {
            let displayed = false;
            for(const s of this.streamings) {   
                if (s.sid === sid) {
                    s.display = true;
                    displayed = true;
                }
            }

            if(!displayed) {
                this.streamings.push({
                    sid: sid,
                    room: room,
                    title: title,
                    display: true
                });
            }
        },

        hideStreaming: function(sid) {
            for (let i = 0; i < this.streamings.length; i++) {
                if (sid === this.streamings[i].sid) {
                    this.streamings.splice(i, 1);
                    this.moving = false;
                    return;
                }
            }
        },

        onRoomChanged: function(room) {
            if (this.room !== room) {
                this.room = room;
                this.streamings = [];
            }
        },

        onStartMoving: function () {
            this.moving = true;
        },

        onEndMoving: function () {
            this.moving = false;
        },
    },
    mounted: function () {
        RoomController.on("room-roles", this.onRoomChanged.bind(this));
    },
    beforeUnmount: function () {},
});
</script>

<style></style>
