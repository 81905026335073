/* View in fullscreen */
export function openFullscreen(element: HTMLElement) {
    const elem = element || document.documentElement;
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    }
}

/* Close fullscreen */
export function closeFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    }
}
