/**
 * Room controller
 * 
 * Fetches room info and
 * provides events to components
 * 
 * Events:
 *   - 'loading' (bool) - Loading status changed
 *   - 'popup'(title, message) - Popup message
 *   - 'room-roles'(room-id, {admin: bool, mod: bool, owner: bool}) - Room roles updated
 *   - 'room-meta'(room-id, { API RESPONSE }) - Room metadata updated. Null if no data yet
 *   - 'section-change' (mode, section.id) - Section changed (for display)
 *   - 'section-change-alt' (mode, section.id) - Alt Section changed (for display)
 *   - 'layout-change' (layout) - Layout mode changed
 *   Rest of room events (socket), except calls
 */

import { abortNamedApiRequest, Request, RequestErrorHandler } from "@asanrom/request-browser";
import { AppEvents } from './app-events';
import { WebSocketController } from './socket';
import { AuthController } from './auth';
import { ApiRoom } from "@/api/api-group-room";
import { Timeouts } from "@/utils/timeout";
import { ApiAccount } from "@/api/api-group-account";
declare global {
    interface Window {
        FORCED_CALL_EXPAND?: boolean;
        ROOM_VERSION?: string;
        ROOM_VERSION_CODE?: string;
        USING_DARK_THEME?: boolean;
        ROOM_FORCED?: string;
    }
}
function $t(s: string): string {
    return s;
}

export class RoomController {
    public static room: string = "";
    public static sectionMode: string = "section";
    public static section: string = "";
    public static sectionModeAlt: string = "section";
    public static sectionAlt: string = "";
    public static layout: string = "single";
    public static isMultiStreaming: boolean = false;
    public static multiStreamingSelected: string = "";
    public static multiStreamingList: string[] = [];
    public static focus: string = window.FORCED_CALL_EXPAND ? "call" : "menu";
    public static theme: string = window.USING_DARK_THEME ? "dark" : "light";
    public static loading: boolean = false;
    public static mod: boolean = true;
    public static admin: boolean = true;
    public static owner: boolean = true;
    public static accessCode: string = "";
    public static meta: any = null;
    private static events: { [key: string]: Array<(...args: any[]) => void> } = {};
    public static editorActive: boolean = false;
    public static activityOn: boolean = true;

    public static init() {
        AppEvents.AddEventListener('room', RoomController.onRoomChanged.bind(this));
        AppEvents.AddEventListener('room-leave', RoomController.onRoomLeave.bind(this));
        AppEvents.AddEventListener('room-roles', RoomController.onRoomRoles.bind(this));
        AppEvents.AddEventListener('room-menu-updated', RoomController.onMenuUpdated.bind(this));
        AppEvents.AddEventListener('room-join-request', RoomController.onJoinRequest.bind(this));
        AppEvents.AddEventListener('room-section-updated', RoomController.onSectionUpdated.bind(this));
        AppEvents.AddEventListener('room-section-deleted', RoomController.onSectionDeleted.bind(this));
        AppEvents.AddEventListener('chat', RoomController.onChat.bind(this));
        AppEvents.AddEventListener('chat-error', RoomController.onChatError.bind(this));
        AppEvents.AddEventListener('chat-delete', RoomController.onChatDelete.bind(this));
        AppEvents.AddEventListener('streaming-start', RoomController.onStreamingStart.bind(this));
        AppEvents.AddEventListener('streaming-end', RoomController.onStreamingEnd.bind(this));

        AppEvents.AddEventListener("auth-status-changed", RoomController.onAuthChanged.bind(this));


        setInterval(RoomController.sendRoomActivity.bind(this), 30000);
    }

    /* Events */

    public static addEventListener(event: string, listener: (...args: any[]) => void) {
        if (!RoomController.events[event]) {
            RoomController.events[event] = [];
        }
        RoomController.events[event].push(listener);
    }

    public static on(event: string, listener: (...args: any[]) => void) {
        RoomController.addEventListener(event, listener);
    }

    public static removeEventListener(event: string, listener: (...args: any[]) => void) {
        if (!RoomController.events[event]) {
            return;
        }
        const i = RoomController.events[event].indexOf(listener);
        if (i >= 0) {
            RoomController.events[event].splice(i, 1);
            if (RoomController.events[event].length === 0) {
                delete RoomController.events[event];
            }
        }
    }

    public static emit(event: string, ...args: any[]) {
        if (!RoomController.events[event]) {
            return;
        }
        for (const listener of RoomController.events[event]) {
            listener(...args);
        }
    }

    /* Actions */

    public static joinRoom(roomId: string) {
        if (RoomController.room === roomId) {
            return; // Already joined
        }

        if (window.ROOM_FORCED && roomId !== window.ROOM_FORCED) {
            location.replace(`${window.location.protocol}//${window.location.host}/room/${encodeURIComponent(roomId)}`);
            return;
        }

        if (!roomId) {
            RoomController.leaveRoom();
        }

        RoomController.loading = true;
        RoomController.emit("loading", RoomController.loading);

        Request.Abort('room-meta-load');

        Request.Do(ApiRoom.GetRoomRoomid(roomId, {}))
        .onSuccess((response: any) => {
            if (RoomController.room !== roomId) {
                RoomController.resetLayout();
            }
            RoomController.room = roomId;
            RoomController.loading = false;
            RoomController.emit("loading", RoomController.loading);
            RoomController.emit("room-meta", roomId, response);
            RoomController.meta = response;
            RoomController.activityOn = !!response.audience;

            abortNamedApiRequest('room-meta-update'); // Abortar cualquier actualización previa

            WebSocketController.joinCallRoom(roomId); // Unirse a la sala para escuchar eventos
        })
        .onRequestError((xhr: any) => {
            RoomController.loading = false;
            RoomController.emit("loading", RoomController.loading);
            new RequestErrorHandler()
                .add(401, "*", () => {
                    // Tal vez el token expiró?
                    AuthController.CheckAuthStatus();
                })
                .add(403, "*", () => {
                    RoomController.emit("room-cant-join", roomId);
                    if (window.ROOM_FORCED) {
                        //App.showRoomAccessDenied();
                    } else {
                       //App.showRoomJoinModal(roomId);
                    }
                })
                .add(404, "*", () => {
                    RoomController.emit("popup", $t("Room not found"), $t("The room you tried to join was not found. This means it was probably deleted."));
                    RoomController.emit("room-deleted", roomId);
                })
                .add(0, "*", () => {
                    // Error de conexión, reintentar
                    RoomController.loading = true;
                    RoomController.emit("loading", RoomController.loading);
                    setTimeout(() => {
                        RoomController.joinRoom(roomId);
                    }, 1000);
                })
                .add("*", "*", () => {
                    // Error del servidor, reintentar
                    RoomController.loading = true;
                    RoomController.emit("loading", RoomController.loading);
                    setTimeout(() => {
                        RoomController.joinRoom(roomId);
                    }, 1000);
                })
                .handle(xhr);
        })
        .onUnexpectedError((err) => {
            console.error('Unexpected error:', err);
        });
    }

    public static leaveRoom() {
        if (!RoomController.room) {
            return; // Nothing to leave
        }

        RoomController.room = "";
        RoomController.section = "";

        RoomController.resetLayout();

        // Abort any join requests
        abortNamedApiRequest('room-meta-load');
        abortNamedApiRequest('room-meta-load');
        abortNamedApiRequest('room-meta-update');

        RoomController.emit("room-meta", "", null); // Remove all data

        WebSocketController.leaveRoom(); // Leave room via socket

        RoomController.loading = false;
        RoomController.emit("loading", RoomController.loading);
    }

    /* Event handlers */

    private static onAuthChanged() {
        if (!AuthController.UID) {
            RoomController.leaveRoom();
        }
    }

    private static onRoomChanged(event: any) {
        if (RoomController.room !== event.room) {
            RoomController.resetLayout();
        }
        RoomController.room = event.room;
        RoomController.mod = !!event.mod;
        RoomController.admin = !!event.admin;
        RoomController.owner = !!event.owner;
        RoomController.accessCode = event.accessCode;

        RoomController.emit("room-roles", RoomController.room, { admin: RoomController.admin, mod: RoomController.mod, owner: RoomController.owner, accessCode: RoomController.accessCode });

        WebSocketController.registerUserActivity(RoomController.room, RoomController.activityOn);
    }

    private static onRoomLeave(event: any) {
        RoomController.room = "";
        RoomController.mod = false;
        RoomController.admin = false;

        RoomController.emit("room-roles", RoomController.room, { admin: RoomController.admin, mod: RoomController.mod });
        RoomController.emit("room-meta", RoomController.room, null);

        RoomController.meta = null;

        switch (event.reason) {
            case "NOT_FOUND":
                if (window.ROOM_FORCED) {
                    //App.showRoomAccessDenied();
                } else {
                    RoomController.emit("popup", $t("Room not found"), $t("The room you tried to join was not found. This means it was probably deleted."));
                }
                break;
            case "DENIED":
                if (window.ROOM_FORCED) {
                    //App.showRoomAccessDenied();
                } else {
                    RoomController.emit("popup", $t("Cannot join the room"), $t("You lack the required permission to join the room."));
                }
                break;
            case "KICKED":
                if (window.ROOM_FORCED) {
                    //App.showRoomKicked();
                } else {
                    RoomController.emit("popup", $t("Kicked"), $t("You have been kicked from the room by a moderator."));
                }
                break;
        }
    }

    private static onRoomRoles(event: any) {
        if (RoomController.room !== event.room) return;

        RoomController.mod = !!event.mod;
        RoomController.admin = !!event.admin;
        RoomController.owner = !!event.owner;

        RoomController.emit("room-roles", RoomController.room, { admin: RoomController.admin, mod: RoomController.mod, owner: RoomController.owner });
    }

    private static onMenuUpdated(event: any) {
        if (RoomController.room !== event.room) return;

        const roomId = event.room;
        Request.Do(ApiRoom.GetRoomRoomid(roomId, {}))
            .onSuccess((response: any) => {
                if (roomId === RoomController.room) {
                    RoomController.emit("room-meta", RoomController.room, response);
                    RoomController.meta = response;
                }
            })
            .onRequestError((xhr: any) => {
                console.error('Error al obtener la información de la sala:', xhr);
            })
            .onUnexpectedError((err) => {
                console.error('Unexpected error:', err);
            });
    }

    private static onJoinRequest(event: any) {
        if (RoomController.room !== event.room) return;
        RoomController.emit(event.event, event);
    }

    private static onSectionUpdated(event: any) {
        if (RoomController.room !== event.room) return;
        if (RoomController.editorActive) return;
        RoomController.emit(event.event, event);
    }

    private static onSectionDeleted(event: any) {
        if (RoomController.room !== event.room) return;
        RoomController.emit(event.event, event);
    }

    private static onChat(event: any) {
        if (RoomController.room !== event.room) return;
        RoomController.emit(event.event, event);
    }

    private static onChatError(event: any) {
        if (RoomController.room !== event.room) return;
        RoomController.emit(event.event, event);
    }

    private static onChatDelete(event: any) {
        if (RoomController.room !== event.room) return;
        RoomController.emit(event.event, event);
    }

    private static onStreamingStart(event: any) {
        if (RoomController.room !== event.room) return;
        RoomController.emit(event.event, event);
    }

    private static onStreamingEnd(event: any) {
        if (RoomController.room !== event.room) return;
        RoomController.emit(event.event, event);
    }

    /* Rooms list */

    public static loadRoomList(callback: (rooms: any[]) => void) {
        abortNamedApiRequest('room-list-load');

        if (!AuthController.isAuthenticated()) {
            // Not registered, we cannot load the list
            //TODO Refactorizar con el código de rooms
            //App.loadingRooms = false;
            return callback([]);
        }
        //TODO Refactorizar con el código nuevo de room
        //App.loadingRooms = true;

        Request.Do(ApiAccount.GetAccountRoomsFav())
        .onSuccess((response: any) => {
            RoomController.savingRoomList = false;
            if (RoomController.requestedRoomListSave) {
                RoomController.requestedRoomListSave = false;
                RoomController.saveRoomList();
            }
            // Asumiendo que la respuesta debe ser procesada y asignada
            RoomController.roomList = response;
        })
        .onRequestError((err, handleErr) => {
            RoomController.savingRoomList = false;
            RoomController.requestedRoomListSave = false;
    
            handleErr(err, {
                unauthorized: () => {
                    // Tal vez el token expiró?
                    AuthController.CheckAuthStatus();
                },
                networkError: () => {
                    // Error de conexión, reintentar
                    Timeouts.Set('room-list-save', 1000, RoomController.saveRoomList.bind(RoomController));
                },
                serverError: () => {
                    // Error del servidor, reintentar
                    Timeouts.Set('room-list-load', 1000, RoomController.loadRoomList.bind(RoomController));
                },
            });
        })
        .onUnexpectedError((err) => {
            console.error('Unexpected error:', err);
        });
    
    }

    private static savingRoomList: boolean = false;
    private static requestedRoomListSave: boolean = false;
    private static roomList: string = "";

    public static saveRoomList(roomList?: any[]) {
        if (roomList) {
            RoomController.roomList = roomList.map((r: any) => r.id || r).join(",");
        }

        if (RoomController.savingRoomList) {
            RoomController.requestedRoomListSave = true;
            return;
        }

        RoomController.savingRoomList = true;

        abortNamedApiRequest('room-list-save');

        if (!AuthController.isAuthenticated()) {
            // Not registered, we cannot save
            RoomController.savingRoomList = false;
            RoomController.requestedRoomListSave = false;
            return;
        }
        
        const roomIds = RoomController.roomList.split(",");


        const saveNextRoom = () => {
            if (roomIds.length === 0) {
                RoomController.savingRoomList = false;
                if (RoomController.requestedRoomListSave) {
                    RoomController.requestedRoomListSave = false;
                    RoomController.saveRoomList();
                }
                return;
            }
    
            const rid = roomIds.shift(); // Obtener el siguiente ID de sala
    
            const body = { rid };
    
            Request.Do(ApiAccount.PostAccountRoomsFav(body))
                .onSuccess(() => {
                    saveNextRoom(); // Guardar la siguiente sala
                })
                .onRequestError((err, handleErr) => {
                    RoomController.savingRoomList = false;
                    RoomController.requestedRoomListSave = false;
                    handleErr(err, {
                        unauthorized: () => {
                            // Tal vez el token expiró?
                            AuthController.CheckAuthStatus();
                        },
                        networkError: () => {
                            // Error de conexión, reintentar
                            Timeouts.Set('room-list-save', 1000, () => {
                                RoomController.saveRoomList(roomIds); // Reintentar desde la sala actual
                            });
                        },
                        serverError: () => {
                            // Error del servidor, reintentar
                            Timeouts.Set('room-list-load', 1000, RoomController.loadRoomList.bind(RoomController));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error('Unexpected error:', err);
                });
        };
    
        saveNextRoom(); // Iniciar la cadena de guardado de salas
    }


    public static joinSection(mode: string, sid: string) {
        RoomController.sectionMode = mode;
        RoomController.section = sid;
        RoomController.emit("section-change", mode, sid);
    }

    public static resetLayout() {
        RoomController.sectionModeAlt = "section";
        RoomController.sectionAlt = "";
        RoomController.emit("section-change-alt", "section", "");
        RoomController.layout = "single";
        RoomController.emit("layout-change", RoomController.layout);
        RoomController.multiStreamingSelected = "";
        RoomController.isMultiStreaming = false;
        RoomController.multiStreamingList = [];
    }

    public static changeSectionAlt(mode: string, sid: string, layout: string) {
        if (layout === "single") {
            RoomController.joinSection(mode, sid);
            RoomController.sectionModeAlt = "section";
            RoomController.sectionAlt = "";
            RoomController.emit("section-change-alt", "section", "");
        } else if (mode !== "multistream" && RoomController.isMultiStreaming) {
            RoomController.isMultiStreaming = false;
            RoomController.sectionModeAlt = mode;
            RoomController.sectionAlt = sid;
            RoomController.emit("section-change-alt", mode, sid);
            RoomController.joinSection("section", RoomController.multiStreamingSelected);
        } else {
            RoomController.sectionModeAlt = mode;
            RoomController.sectionAlt = sid;
            RoomController.emit("section-change-alt", mode, sid);
        }

        RoomController.layout = layout;
        RoomController.emit("layout-change", layout);
    }

    public static changeFocus(id: string) {
        RoomController.focus = id;
        RoomController.emit("tab-focus", id);
    }

    public static showStreamingSectionInList(id: string) {
        RoomController.emit("streaming-list-add", id);
    }

    public static updateRoomAccessList() {
        RoomController.emit("update-room-access-list");
    }

    public static onMultiStreamingShow() {
        if (RoomController.sectionModeAlt !== "multistream") {
            RoomController.sectionModeAlt = "multistream";
            RoomController.sectionAlt = "";
            RoomController.emit("section-change-alt", RoomController.sectionModeAlt, RoomController.sectionAlt);
            if (["right", "left", "rightmin", "leftmin"].indexOf(RoomController.layout) === -1) {
                RoomController.layout = "rightmin";
                RoomController.emit("layout-change", RoomController.layout);
            }
        }
        RoomController.isMultiStreaming = true;
        RoomController.emit("multi-streaming-status-change", true);
    }

    public static onMultiStreamingHide() {
        if (RoomController.sectionModeAlt === "multistream") {
            RoomController.sectionModeAlt = "section";
            RoomController.sectionAlt = "";
            RoomController.emit("section-change-alt", "section", "");
            RoomController.layout = "single";
            RoomController.emit("layout-change", RoomController.layout);
        }
        RoomController.multiStreamingSelected = "";
        RoomController.isMultiStreaming = false;
        RoomController.multiStreamingList = [];
        RoomController.emit("multi-streaming-status-change", true);
        RoomController.emit("multi-streaming-list-change", []);
    }

    public static onMultiStreamingList(list: string[]) {
        RoomController.multiStreamingList = list;
        RoomController.emit("multi-streaming-list-change", list);
    }

    public static onMultiStreamingMainChange(sid: string) {
        RoomController.multiStreamingSelected = sid;
        RoomController.emit("multi-streaming-section-change", sid);
    }

    public static setActiveEditor(b: boolean) {
        RoomController.editorActive = b;
    }

    public static hasStreaming(meta: any): boolean {
        if (!meta || !meta.sections) {
            return false;
        }
        for (const section of meta.sections) {
            if (section.type === "streaming" && section.visible) {
                return true;
            }
        }

        return false;
    }

    public static sendRoomActivity() {
        if (!RoomController.room) {
            return;
        }
        WebSocketController.registerUserActivity(RoomController.room, RoomController.activityOn);
    }

    public static changeTheme(isDark: boolean) {
        RoomController.theme = isDark ? "dark" : "light";
        RoomController.emit("page-theme", RoomController.theme);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    RoomController.init();
});
