<template>
  <div class="input-group">
    <input type="text" class="form-control m-3 search-bar" :placeholder="$t(placeholder)" v-model="query"
      @input="handleInput" :class="{ 'min-search': bar_height }" />
    <button class="search-button m-3" type="button" id="button-addon2" :class="{ 'min-search': bar_height }"  @click="handleSearch">
      <i class="fa-solid fa-magnifying-glass"></i>
    </button>
  </div>
</template>

<script lang="ts">

import { useVModel } from "../../utils/v-model";

export default {
  props: {
    textplaceholder: String,
    bar_h: Boolean,
    },

  setup(props) {
        return {
          placeholder: useVModel(props, "textplaceholder"),  
          bar_height: useVModel(props, "bar_h"),  
        };
    },
    data() {
    return {
      query: ''
    };
  },
  methods: {
    handleInput() {
      this.$emit('input', this.query);
    },
    handleSearch() {
      this.$emit('search', this.query);
    },
  }
};
</script>

<style scoped>
.search-bar {
  border-radius: 0;
  height: 25px;
  background-color: var(--bar-bg-color);
  border-color: var(--grey-maya);
  color: var(--black-maya);
  padding: 15px;
  margin-right: 0 !important;
  border-radius: 5px;
}

.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  height: 32px;
  background-color: var(--bar-bg-color);
  color: var(--black-maya);
  border: none;
  margin-left: 0 !important;
}

.search-bar.min-search {
  height: 20px;
  padding: 5px;
  font-size: small ;
  border-radius: 5px;

}

.search-button.min-search  {
  height: 20px;
  border-radius: 5px;
}


/*placeholder for various browsers*/
.search-bar::placeholder {
  color:var(--black-maya);
}

.search-bar::-webkit-input-placeholder {
  color: var(--black-maya);
}

.search-bar::-moz-placeholder {
  color: var(--black-maya);
}
</style>