<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus" @keydown="escapeToClose">
        <div class="modal-dialog h-75" role="document" @click="stopPropagationEvent">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">{{ $t("Create new room") }}</div>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>
                <div class="modal-body d-flex p-0 pe-3">
                    <div class="side-menu col-2 col-lg-3">
                        <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'new-room' }]"
                            @click.prevent="showDiv('new-room')">
                            <div class="side-bar-option-icon"><i class="fa-solid fa-plus"></i></div>
                            <div class="side-bar-option-text">{{ $t("Create room") }}</div>
                        </router-link>

                        <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'room-template' }]"
                            @click.prevent="showDiv('room-template')">
                            <div class="side-bar-option-icon"><i class="fa-solid fa-clone"></i></div>
                            <div class="side-bar-option-text">{{ $t("Room templates") }}</div>
                        </router-link>

                        <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'join-room' }]"
                            @click.prevent="showDiv('join-room')">
                            <div class="side-bar-option-icon"><i class="fa-solid fa-paperclip"></i></div>
                            <div class="side-bar-option-text">{{ $t("Join an existing room") }}</div>
                        </router-link>
                    </div>
                    <div class="room-content col-10 col-lg-9 ps-3">
                        <div v-if="visibleDiv === 'new-room'">
                            <label class="headline-modal">{{ $t("Room name") }}</label>
                            <input type="text" name="room-title" autocomplete="off" v-model="roomName"
                                class="form-control" maxlength="80" />
                                <div v-if="error_name" class="error-message">{{ error_name }}</div>
                            <label class="headline-modal">{{ $t("Room description") }}</label>
                            <textarea name="room-description" autocomplete="off" v-model="roomDescription"
                                class="form-control" maxlength="255">
                            </textarea>

                            <label class="headline-modal">{{ $t("Create under the responsibility of") }}</label>
                            <select name="room-template" v-model="roomTemplate" class="form-select">
                                <option value="">{{ username }}</option>
                            </select>
                            <div v-if="error" class="error-message">{{ error }}</div>
                            <div class="button-container">
                                <button type="button" class="btn btn-mr"
                                    :class="{ 'btn-primary': !danger, 'btn-danger': danger }" @click="createRoom">
                                    {{ $t("Create room") }}
                                </button>
                            </div>
                        </div>

                        <div v-if="visibleDiv === 'room-templates'">
                        </div>

                        <div v-if="visibleDiv === 'join-room'">
                            <label class="headline-modal">{{ $t("Join an existing room") }}</label>
                            <input type="text" name="room-title" autocomplete="off" v-model="roomName"
                                class="form-control" maxlength="255" />
                            <div class="button-container">
                                <button type="button" class="btn btn-mr"
                                    :class="{ 'btn-primary': !danger, 'btn-danger': danger }" @click="joinRoom">
                                    {{ $t("Join room") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useVModel } from "../../utils/v-model";
import { ApiRoom } from "@/api/api-group-room";
import { AppEvents } from "@/control/app-events";
import { Request } from "@asanrom/request-browser";
import { NewRoomRequest } from "@/api/definitions";
import { getUniqueStringId } from "@/utils/unique-id";
import { AuthController } from "@/control/auth";
import { Timeouts } from "@/utils/timeout";

export default defineComponent({
    name: "CreateRoomModal",
    emits: ["update:display", "confirm"],
    props: {
        display: Boolean,
        title: String,
        message: String,
        danger: Boolean,
    },
    setup(props) {
        const visibleDiv = ref("");
        return {
            displayStatus: useVModel(props, "display"),
            busy: false,
            visibleDiv,
            selectedDiv: 'new-room',
            loadRequestId: getUniqueStringId(),
            saveRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            roomName: "",
            roomDescription: "",
            roomTemplate: "",
            username: AuthController.Username,
            tfa: false,
            error: "",
            error_name: "",
            error_description: "",
        };
    },
    methods: {
        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.displayStatus = false;
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        joinRoom: function () {

        },

        createRoom: function () {
            if (this.busy) {
                return;
            }

            if (!this.roomName) {
                this.error = this.$t("The room name is required");
                return;
            }

            const request: NewRoomRequest = {
                name: this.roomName,
                description: this.roomDescription,
            };

            Request.Do(ApiRoom.PostRoom(request))
                .onSuccess((response) => {
                    this.busy = false;
                    this.$router.push({ name: "room", params: { roomId: response.room_id } });
                    this.$showSnackBar(this.$t("Room added to the list"));
                    this.reset();
                    this.close();
                })
                .onRequestError((err, handleErr) => {
                    this.busy = false;
                    handleErr(err, {
                        unauthorized: () => {
                            AppEvents.Emit("Unauthorized");
                            this.error = this.$t("Access denied");
                        },
                        badRequestTooMany: () => {
                            this.error = this.$t("Bad request");
                        },
                        badRequestInvalidTemplate: () => {
                            this.error = this.$t("Bad request");
                        },
                        badRequestInvalidDescription: () => {
                            this.error_description = this.$t("Description should be between 3 and 255 characters");
                        },
                        badRequestInvalidName: () => {
                            this.error_name = this.$t("Name should be between 3 and 80 characters");
                        },
                        badRequestInvalidOrganization: () => {
                            this.error = this.$t("Bad request");
                        },
                        badRequest: () => {
                            this.error = this.$t("Bad request");
                        },
                        serverError: () => {
                            this.error = this.$t("Internal server error");
                        },
                        networkError: () => {
                            this.error = this.$t("Could not connect to the server");
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    this.busy = false;
                    console.error(err);
                    this.error = err.message;
                });
        },

        reset: function () {
            this.error = "";
            this.roomName = "";
            this.roomDescription = "";
        },

        showDiv: function (divName) {
            this.reset();
            this.visibleDiv = divName;
            this.selectedDiv = divName;
        },
    },
    mounted: function () {
        if (this.display) {
            this.$autoFocus();
        }
        this.showDiv('new-room');

        if (AuthController.isAuthenticated()) {
            this.$autoFocus();
        } else {
            this.$requireLogin();
        }
    },
    beforeUnmount: function () {
        Request.Abort(this.saveRequestId);
        Timeouts.Abort(this.loadRequestId);
        Request.Abort(this.loadRequestId);
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$autoFocus();
            }
        },
    },
});
</script>
<style scoped>
@import "@/style/modals/create-room.css";
</style>
