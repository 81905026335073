<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus" @keydown="escapeToClose">
        <div class="modal-dialog h-75" role="document" @click="stopPropagationEvent">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">{{ $t("User configuration") }}</div>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>
                <div class="modal-body d-flex p-0 pe-3">
                    <div class="side-menu col-2 col-lg-3">
                        <div class="accordion accordion-flush" id="accordionFlushConfig">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button
                                            class="accordion-button"
                                            type="button"
                                            :class="{ collapsed: !isAccordionOpen }"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            :aria-expanded="isAccordionOpen ? 'true' : 'false'"
                                            aria-controls="collapseOne"
                                            @click="toggleAccordion"
                                        >
                                            {{ $t("Profile") }}
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse"
                                        :class="{ show: isAccordionOpen }"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="accordion-body">
                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'profile' }]" @click.prevent="showDiv('profile')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-tag"></i></div>
                                                <div class="side-bar-option-text">{{ $t("Profile") }}</div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            {{ $t("Account") }}
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="accordion-body">
                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'username' }]" @click.prevent="showDiv('username')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-users"></i></div>
                                                <div class="side-bar-option-text">{{ $t("Username") }}</div>
                                            </router-link>

                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'email' }]" @click.prevent="showDiv('email')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-magnifying-glass"></i></div>
                                                <div class="side-bar-option-text">{{ $t("Email") }}</div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            {{ $t("Security") }}
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="accordion-body">
                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'password' }]" @click.prevent="showDiv('password')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-comments"></i></div>
                                                <div class="side-bar-option-text">{{ $t("Password") }}</div>
                                            </router-link>

                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'authemtication' }]" @click.prevent="showDiv('authentication')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-phone"></i></div>
                                                <div class="side-bar-option-text">
                                                    {{ $t("Two-step authentication") }}
                                                </div>
                                            </router-link>

                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'sessions' }]" @click.prevent="showDiv('sessions')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-key"></i></div>
                                                <div class="side-bar-option-text" @click="showDiv('sessions')">{{ $t("Sessions") }}</div>
                                            </router-link>

                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'api-codes' }]" @click.prevent="showDiv('api-codes')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-key"></i></div>
                                                <div class="side-bar-option-text" @click="showDiv('api-codes')">{{ $t("API codes") }}</div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            {{ $t("Organizations") }}
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="accordion-body">
                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'organizations' }]" @click.prevent="showDiv('organizations')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-photo-film"></i></div>
                                                <div class="side-bar-option-text">
                                                    {{ $t("Organizations") }}
                                                </div>
                                            </router-link>

                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'invitations' }]" @click.prevent="showDiv('invitations')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-calendar-week"></i></div>
                                                <div class="side-bar-option-text">
                                                    {{ $t("Invitations to the organization") }}
                                                </div>
                                            </router-link>

                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'create-org' }]" @click.prevent="showDiv('create-org')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-calendar-week"></i></div>
                                                <div class="side-bar-option-text">
                                                    {{ $t("Create organization") }}
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFive">
                                        <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                        >
                                            {{ $t("Billing") }}
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="accordion-body">
                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'billing' }]" @click.prevent="showDiv('billing')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-photo-film"></i></div>
                                                <div class="side-bar-option-text">
                                                    {{ $t("Billing and plans") }}
                                                </div>
                                            </router-link>

                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'subscription' }]" @click.prevent="showDiv('subscription')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-calendar-week"></i></div>
                                                <div class="side-bar-option-text">
                                                    {{ $t("Subscription") }}
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingSix">
                                        <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSix"
                                            aria-expanded="false"
                                            aria-controls="collapseSix"
                                        >
                                            {{ $t("Manage site") }}
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSix"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="headingSix"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="accordion-body">
                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'user-admin' }]" @click.prevent="showDiv('user-admin')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-photo-film"></i></div>
                                                <div class="side-bar-option-text">
                                                    {{ $t("Users (Admin)") }}
                                                </div>
                                            </router-link>

                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'servers-admin' }]" @click.prevent="showDiv('servers-admin')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-calendar-week"></i></div>
                                                <div class="side-bar-option-text">
                                                    {{ $t("ICE servers (Admin)") }}
                                                </div>
                                            </router-link>

                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'plans-admin' }]" @click.prevent="showDiv('plans-admin')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-photo-film"></i></div>
                                                <div class="side-bar-option-text">
                                                    {{ $t("Plans (Admin)") }}
                                                </div>
                                            </router-link>

                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'templates-admin' }]" @click.prevent="showDiv('templates-admin')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-calendar-week"></i></div>
                                                <div class="side-bar-option-text">
                                                    {{ $t("Room templates (Admin)") }}
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingSeven">
                                        <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSeven"
                                            aria-expanded="false"
                                            aria-controls="collapseSeven"
                                        >
                                            {{ $t("Dangerous options") }}
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSeven"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="headingSeven"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="accordion-body">
                                            <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'delete-account' }]" @click.prevent="showDiv('delete-account')">
                                                <div class="side-bar-option-icon"><i class="fa-solid fa-photo-film"></i></div>
                                                <div class="side-bar-option-text">
                                                    {{ $t("Delete account") }}
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--PROFILE OPTIONS-->
                    <div class="room-content col-10 col-lg-9 ps-3">
                        <div v-if="visibleDiv === 'profile'">
                            <label class="headline-modal">{{ $t("Profile name" + ":") }} <b>Username</b></label>
                            <label for="image-upload" class="headline-modal" v-if="!imageUrl">{{ $t("Profile picture") }}</label>
                            <input type="file" id="image-upload" @change="previewImage" accept="image/*" v-if="!imageUrl" />

                            <div v-if="imageUrl">
                                <img :src="imageUrl" alt="Image preview" style="max-width: 200px; max-height: 200px" />
                                <div class="row" style="padding-left: 10px; padding-right: 10px">
                                    <button
                                        @click="editImage"
                                        class="col-2 btn btn-cancel edit-image-button"
                                    >
                                        {{ $t("Upload image") }}
                                    </button>
                                    <button
                                        @click="removeImage"
                                        class="col-2 btn btn-primary remove-image-button"
                                    >
                                        {{ $t("Delete image") }}
                                    </button>
                                </div>
                            </div>
                            <div class="button-container">
                                <button
                                    type="button"
                                    class="btn btn-mr"
                                    @click="confirm"
                                >
                                    {{ $t("Save changes") }}
                                </button>
                            </div>

                            <label class="headline-modal">{{ $t("Profile name") }}</label>
                            <input
                                type="text"
                                name="profile-name"
                                autocomplete="off"
                                v-model="profileName"
                                class="form-control"
                                maxlength="255"
                            />

                            <label class="headline-modal">{{ $t("Profile description") }}</label>
                            <textarea
                                name="profile-description"
                                autocomplete="off"
                                v-model="profileDescription"
                                class="form-control"
                                maxlength="255"
                            >
                            </textarea>

                            <label class="headline-modal">{{ $t("Personal web (URL)") }}</label>
                            <input
                                type="text"
                                name="profile-web"
                                autocomplete="off"
                                v-model="profileWeb"
                                class="form-control"
                                maxlength="255"
                            />

                            <div class="button-container">
                                <button
                                    type="button"
                                    class="btn btn-mr"
                                    :class="{ 'btn-primary': !danger, 'btn-danger': danger }"
                                    @click="confirm"
                                >
                                    {{ $t("Save changes") }}
                                </button>
                            </div>
                        </div>

                        <div v-if="visibleDiv === 'username'">
                            <label class="headline-modal">{{ $t("Profile name") }}</label>
                            <input
                                type="text"
                                name="profile-name"
                                autocomplete="off"
                                v-model="profileName"
                                class="form-control"
                                maxlength="255"
                            />
                            <div class="button-container">
                                <button
                                    type="button"
                                    class="btn btn-mr"
                                    :class="{ 'btn-primary': !danger, 'btn-danger': danger }"
                                    @click="confirm"
                                >
                                    {{ $t("Update username") }}
                                </button>
                            </div>
                        </div>

                        <div v-if="visibleDiv === 'email'">
                            <label class="headline-modal">{{ $t("Profile email") }}</label>
                            <input
                                type="text"
                                name="profile-email"
                                autocomplete="off"
                                v-model="profileEmail"
                                class="form-control"
                                maxlength="255"
                            />
                            <div class="button-container">
                                <button
                                    type="button"
                                    class="btn btn-mr"
                                    :class="{ 'btn-primary': !danger, 'btn-danger': danger }"
                                    @click="confirm"
                                >
                                    {{ $t("Update mail") }}
                                </button>
                            </div>
                        </div>

                        <div v-if="visibleDiv === 'password'">
                            <label class="headline-modal">{{ $t("Current password") }}</label>
                            <input
                                type="password"
                                name="current-pass"
                                autocomplete="off"
                                v-model="currentPass"
                                class="form-control"
                                maxlength="255"
                            />
                            <label class="headline-modal">{{ $t("New password") }}</label>
                            <input
                                type="password"
                                name="new-pass"
                                autocomplete="off"
                                v-model="newPass"
                                class="form-control"
                                maxlength="255"
                            />
                            <label class="headline-modal">{{ $t("Confirm password") }}</label>
                            <input
                                type="password"
                                name="confirm-pass"
                                autocomplete="off"
                                v-model="confirmPass"
                                class="form-control"
                                maxlength="255"
                            />
                            <div class="button-container">
                                <button
                                    type="button"
                                    class="btn btn-mr"
                                    :class="{ 'btn-primary': !danger, 'btn-danger': danger }"
                                    @click="confirm"
                                >
                                    {{ $t("Change password") }}
                                </button>
                            </div>
                        </div>

                        <div v-if="visibleDiv === 'authentication'"></div>

                        <div v-if="visibleDiv === 'sessions'"></div>

                        <div v-if="visibleDiv === 'api-codes'"></div>

                        <div v-if="visibleDiv === 'organizations'"></div>

                        <div v-if="visibleDiv === 'invitations'"></div>

                        <div v-if="visibleDiv === 'create-org'">
                            <label class="headline-modal">{{ $t("Organization name") }}</label>
                            <input type="text" name="org-name" autocomplete="off" v-model="orgName" class="form-control" maxlength="255" />

                            <label class="headline-modal">{{ $t("Organization description") }}</label>
                            <textarea
                                name="org-description"
                                autocomplete="off"
                                v-model="orgDescription"
                                class="form-control"
                                maxlength="255"
                            >
                            </textarea>

                            <label class="headline-modal">{{ $t("Website (URL)") }}</label>
                            <input type="text" name="website" autocomplete="off" v-model="website" class="form-control" maxlength="255" />

                            <div class="button-container">
                                <button
                                    type="button"
                                    class="btn btn-mr"
                                    :class="{ 'btn-primary': !danger, 'btn-danger': danger }"
                                    @click="confirm"
                                >
                                    {{ $t("Create") }}
                                </button>
                            </div>
                        </div>

                        <div v-if="visibleDiv === 'billing'"></div>

                        <div v-if="visibleDiv === 'subscription'"></div>

                        <div v-if="visibleDiv === 'user-admin'"></div>

                        <div v-if="visibleDiv === 'servers-admin'"></div>

                        <div v-if="visibleDiv === 'plans-admin'"></div>

                        <div v-if="visibleDiv === 'templates-admin'"></div>

                        <div v-if="visibleDiv === 'delete-account'">
                            <label class="headline-modal">{{ $t("Profile name") }} <b>Username</b></label>

                            <label class="headline-modal">{{ $t("Enter your username as confirmation:") }}</label>

                            <input
                                type="text"
                                name="user-confirm"
                                autocomplete="off"
                                v-model="userConfirm"
                                class="form-control"
                                maxlength="255"
                            />

                            <label class="headline-modal">{{
                                $t(
                                    "Note: All your account data will be deleted. You cannot do this if you own rooms or have an active paid subscription.",
                                )
                            }}</label>
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                    v-model="destructiveAction"
                                />
                                <label class="form-check-label" for="flexSwitchCheckDefault">{{
                                    $t("I understand that this is a destructive action with non-reversible effects.")
                                }}</label>
                            </div>

                            <div class="button-container">
                                <button
                                    type="button"
                                    class="btn btn-mr"
                                    :class="{ 'btn-primary': !danger, 'btn-danger': danger }"
                                    @click="confirm"
                                >
                                    {{ $t("Delete account") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useVModel } from "@/utils/v-model";
import { ApiRoom } from "@/api/api-group-room";
import { AppEvents } from "@/control/app-events";
import { Request } from "@asanrom/request-browser";
import { NewRoomRequest } from "@/api/definitions";

export default defineComponent({
    name: "AddUserConfig",
    emits: ["update:display", "confirm"],
    props: {
        display: Boolean,
        title: String,
        message: String,
        danger: Boolean,
    },
    setup(props) {
        const visibleDiv = ref("");
        return {
            displayStatus: useVModel(props, "display"),
            visibleDiv,
            selectedDiv: 'profile',
            error: "",
            busy: false,
            profileName: "",
            profileDescription: "",
            profileWeb: "",
            profileEmail: "",
            currentPass: "",
            newPass: "",
            confirmPass: "",
            orgName: "",
            orgDescription: "",
            website: "",
            userConfirm: "",
        };
    },
    data() {
        return {
            imageUrl: null,
            destructiveAction: false,
            isAccordionOpen: true,
            
        };
    },
    methods: {
        close: function () {
            this.displayStatus = false;
        },
        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.displayStatus = false;
            }
        },
        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },
        confirm: function () {
            if (this.busy) {
                return;
            }
            if (!this.profileName) {
                this.error = this.$t("The room name is required");
                return;
            }
            if (this.busy || !this.imageUrl) {
                return;
            }
            this.busy = true;
            const formData = new FormData();
            const imageFile = this.$refs.imageUpload.files[0];
            formData.append("image", imageFile);
            const request: NewRoomRequest = {
                name: this.profileName,
                // profileName: this.profileName,
                // profileDescription: this.profileDescription,
                // profileWeb: this.profileWeb,
                // profileEmail: this.profileEmail,
                // currentPass: this.currentPass,
                // newPass: this.newPass,
                // confirmPass: this.confirmPass,
                // orgName: this.orgName,
                // orgDescription: this.orgDescription,
                // website: this.website,
                // userConfirm: this.userConfirm,
            };
            Request.Do(ApiRoom.PostRoom(request))
                .onSuccess(() => {
                    AppEvents.ShowSnackBar(this.$t("Room added to the list"));
                })
                .onRequestError((err) => {
                    this.busy = false;
                    Request.ErrorHandler()
                        .add(401, "*", () => {
                            AppEvents.Emit("Unauthorized");
                            this.error = this.$t("Access denied");
                        })
                        .add(400, "INVALID_ORGANIZATION", () => {
                            this.error = this.$t("The specified organization is invalid");
                        })
                        .add(400, "INVALID_NAME", () => {
                            this.error = this.$t("The specified name is invalid");
                        })
                        .add(400, "INVALID_DESCRIPTION", () => {
                            this.error = this.$t("The specified description is invalid");
                        })
                        .add(400, "TOO_MANY", () => {
                            this.error = this.$t("There are too many rooms");
                        })
                        .add(400, "INVALID_TEMPLATE", () => {
                            this.error = this.$t("The specified template is invalid");
                        })
                        .add(400, "*", () => {
                            this.error = this.$t("Bad request");
                        })
                        .add(500, "*", () => {
                            this.error = this.$t("Internal server error");
                        })
                        .add("*", "*", () => {
                            this.error = this.$t("Could not connect to the server");
                        })
                        .handle(err);
                })
                .onUnexpectedError((err) => {
                    this.busy = false;
                    console.error(err);
                    this.error = err.message;
                });
            fetch("URL_DE_TU_API", {
                method: "POST",
                body: formData,
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error("Network response was not ok.");
                })
                .then(() => {
                    this.busy = false;
                    this.imageUrl = null;
                    this.$refs.imageUpload.value = "";
                })
                .catch((error) => {
                    console.error("There has been a problem with your fetch operation:", error);
                    this.busy = false;
                });
        },
        showDiv: function (divName) {
            this.visibleDiv = divName;
            this.selectedDiv = divName;
        },
        previewImage(event) {
            if (event.target.files && event.target.files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageUrl = e.target.result;
                };

                reader.readAsDataURL(event.target.files[0]);
            }
        },
        removeImage() {
            this.imageUrl = "";
        },
        editImage() {
            this.imageUrl = "";
            document.getElementById("image-upload").click();
        },
        toggleAccordion() {
            this.isAccordionOpen = !this.isAccordionOpen; // Cambia el estado del acordeón
        },
    },
    mounted: function () {
        if (this.display) {
            this.$autoFocus();
        }
        this.showDiv('profile');
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$autoFocus();
            }
        },
    },
});
</script>
<style scoped>
@import "@/style/routes/configuration/user-config.css";
</style>
