// API bindings: streaming (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { StreamingResponse, StreamingLiveResponse, StreamingLiveStats, StreamingStatsItem, StreamingVideoList, StreamingVideo, ChangeTitleRequst, StreamingPublishDetails, StreamingConfig, RoomPictureResponse } from "./definitions";

export class ApiStreaming {
    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}/streaming
     * Get streaming section status
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionidStreaming(roomId: string, sectionId: string, headerParams: GetRoomRoomidSectionSectionidStreamingHeaderParameters): RequestParams<StreamingResponse, GetRoomRoomidSectionSectionidStreamingErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}/streaming/live/{resolution}
     * Get PlayList URL to watch live streaming
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param resolution Resolution name
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionidStreamingLiveResolution(roomId: string, sectionId: string, resolution: string, headerParams: GetRoomRoomidSectionSectionidStreamingLiveResolutionHeaderParameters): RequestParams<StreamingLiveResponse, GetRoomRoomidSectionSectionidStreamingLiveResolutionErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/live/${encodeURIComponent(resolution)}`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}/streaming/stats/live
     * Get streaming live stars
     * The users must call this enpoint each 30 seconds to count them as spectators
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionidStreamingStatsLive(roomId: string, sectionId: string, headerParams: GetRoomRoomidSectionSectionidStreamingStatsLiveHeaderParameters): RequestParams<StreamingLiveStats, GetRoomRoomidSectionSectionidStreamingStatsLiveErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/stats/live`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}/streaming/stats/avg
     * Get streaming spectators stats (Average)
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionidStreamingStatsAvg(roomId: string, sectionId: string, queryParams: GetRoomRoomidSectionSectionidStreamingStatsAvgQueryParameters): RequestParams<StreamingStatsItem[], GetRoomRoomidSectionSectionidStreamingStatsAvgErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/stats/avg` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}/streaming/videos
     * Get list of sreaming videos (recordings of past streams)
     * It will return a max amount of items with the information to get the next page
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param queryParams Query parameters
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionidStreamingVideos(roomId: string, sectionId: string, queryParams: GetRoomRoomidSectionSectionidStreamingVideosQueryParameters, headerParams: GetRoomRoomidSectionSectionidStreamingVideosHeaderParameters): RequestParams<StreamingVideoList, GetRoomRoomidSectionSectionidStreamingVideosErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/videos` + generateURIQuery(queryParams)),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}/streaming/videos/{videoId}
     * Get streaming Video
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param videoId Video ID
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionidStreamingVideosVideoid(roomId: string, sectionId: string, videoId: string, headerParams: GetRoomRoomidSectionSectionidStreamingVideosVideoidHeaderParameters): RequestParams<StreamingVideo, GetRoomRoomidSectionSectionidStreamingVideosVideoidErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/videos/${encodeURIComponent(videoId)}`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/streaming/videos/{videoId}
     * Change video title (ADMIN)
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param videoId Video ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidStreamingVideosVideoid(roomId: string, sectionId: string, videoId: string, body: ChangeTitleRequst): RequestParams<void, PostRoomRoomidSectionSectionidStreamingVideosVideoidErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/videos/${encodeURIComponent(videoId)}`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/section/{sectionId}/streaming/videos/{videoId}
     * Delete streaming Video (ADMIN)
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param videoId Video ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidSectionSectionidStreamingVideosVideoid(roomId: string, sectionId: string, videoId: string): RequestParams<void, DeleteRoomRoomidSectionSectionidStreamingVideosVideoidErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/videos/${encodeURIComponent(videoId)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}/streaming/videos/{videoId}/{resolution}
     * Get streaming Video playlist
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param videoId Video ID
     * @param resolution Resolution name
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionidStreamingVideosVideoidResolution(roomId: string, sectionId: string, videoId: string, resolution: string, headerParams: GetRoomRoomidSectionSectionidStreamingVideosVideoidResolutionHeaderParameters): RequestParams<StreamingLiveResponse, GetRoomRoomidSectionSectionidStreamingVideosVideoidResolutionErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/videos/${encodeURIComponent(videoId)}/${encodeURIComponent(resolution)}`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/streaming/videos/{videoId}/request_encode
     * Request video to be encoded to MP4 (for site admins)
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param videoId Video ID
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidStreamingVideosVideoidRequestencode(roomId: string, sectionId: string, videoId: string): RequestParams<void, PostRoomRoomidSectionSectionidStreamingVideosVideoidRequestencodeErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/videos/${encodeURIComponent(videoId)}/request_encode`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}/streaming/publish/details
     * Get details to publish to the streaming section (ADMIN)
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionidStreamingPublishDetails(roomId: string, sectionId: string): RequestParams<StreamingPublishDetails, GetRoomRoomidSectionSectionidStreamingPublishDetailsErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/publish/details`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/streaming/kill
     * Kills streaming in progress  (ADMIN)
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidStreamingKill(roomId: string, sectionId: string): RequestParams<void, PostRoomRoomidSectionSectionidStreamingKillErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/kill`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/streaming/key/refresh
     * Invalidates current streaming key and creates a new one
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidStreamingKeyRefresh(roomId: string, sectionId: string): RequestParams<void, PostRoomRoomidSectionSectionidStreamingKeyRefreshErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/key/refresh`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/streaming/title
     * Changes streaming title
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidStreamingTitle(roomId: string, sectionId: string, body: ChangeTitleRequst): RequestParams<void, PostRoomRoomidSectionSectionidStreamingTitleErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/title`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}/streaming/config
     * Get Streaming configuration
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionidStreamingConfig(roomId: string, sectionId: string): RequestParams<StreamingConfig, GetRoomRoomidSectionSectionidStreamingConfigErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/config`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/streaming/config
     * Set Streaming configuration
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidStreamingConfig(roomId: string, sectionId: string, body: StreamingConfig): RequestParams<void, PostRoomRoomidSectionSectionidStreamingConfigErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/config`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/streaming/thumb
     * Uploads a new room image
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param formParams FromData parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidStreamingThumb(roomId: string, sectionId: string, formParams: PostRoomRoomidSectionSectionidStreamingThumbFormParameters): RequestParams<RoomPictureResponse, PostRoomRoomidSectionSectionidStreamingThumbErrorHandler> {
        const formData = new FormData();

        formParams.file !== undefined && formData.append("file", formParams.file);

        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/thumb`),
            form: formData,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_FILE", handler.badRequestInvalidFile)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/section/{sectionId}/streaming/thumb
     * Deletes thumbnail
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidSectionSectionidStreamingThumb(roomId: string, sectionId: string): RequestParams<void, DeleteRoomRoomidSectionSectionidStreamingThumbErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/streaming/thumb`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Error handler for GetRoomRoomidSectionSectionidStreaming
 */
export type GetRoomRoomidSectionSectionidStreamingErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidSectionSectionidStreaming
 */
export interface GetRoomRoomidSectionSectionidStreamingHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for GetRoomRoomidSectionSectionidStreamingLiveResolution
 */
export type GetRoomRoomidSectionSectionidStreamingLiveResolutionErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidSectionSectionidStreamingLiveResolution
 */
export interface GetRoomRoomidSectionSectionidStreamingLiveResolutionHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for GetRoomRoomidSectionSectionidStreamingStatsLive
 */
export type GetRoomRoomidSectionSectionidStreamingStatsLiveErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidSectionSectionidStreamingStatsLive
 */
export interface GetRoomRoomidSectionSectionidStreamingStatsLiveHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for GetRoomRoomidSectionSectionidStreamingStatsAvg
 */
export type GetRoomRoomidSectionSectionidStreamingStatsAvgErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Query parameters for GetRoomRoomidSectionSectionidStreamingStatsAvg
 */
export interface GetRoomRoomidSectionSectionidStreamingStatsAvgQueryParameters {
    /**
     * Chosen timestamp, set 0 by default to take stats from now
     */
    from?: number;

    /**
     * Interval: "1h" | "6h" | "12h" | "1d" | "3d" | "1w" | "1M" | "3M" | "6M" | "1y" | "all"
     */
    interval?: string;
}

/**
 * Error handler for GetRoomRoomidSectionSectionidStreamingVideos
 */
export type GetRoomRoomidSectionSectionidStreamingVideosErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Query parameters for GetRoomRoomidSectionSectionidStreamingVideos
 */
export interface GetRoomRoomidSectionSectionidStreamingVideosQueryParameters {
    /**
     * Page. Starting by 1.
     */
    page?: number;
}

/**
 * Header parameters for GetRoomRoomidSectionSectionidStreamingVideos
 */
export interface GetRoomRoomidSectionSectionidStreamingVideosHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for GetRoomRoomidSectionSectionidStreamingVideosVideoid
 */
export type GetRoomRoomidSectionSectionidStreamingVideosVideoidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidSectionSectionidStreamingVideosVideoid
 */
export interface GetRoomRoomidSectionSectionidStreamingVideosVideoidHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for PostRoomRoomidSectionSectionidStreamingVideosVideoid
 */
export type PostRoomRoomidSectionSectionidStreamingVideosVideoidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for DeleteRoomRoomidSectionSectionidStreamingVideosVideoid
 */
export type DeleteRoomRoomidSectionSectionidStreamingVideosVideoidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidSectionSectionidStreamingVideosVideoidResolution
 */
export type GetRoomRoomidSectionSectionidStreamingVideosVideoidResolutionErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidSectionSectionidStreamingVideosVideoidResolution
 */
export interface GetRoomRoomidSectionSectionidStreamingVideosVideoidResolutionHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for PostRoomRoomidSectionSectionidStreamingVideosVideoidRequestencode
 */
export type PostRoomRoomidSectionSectionidStreamingVideosVideoidRequestencodeErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidSectionSectionidStreamingPublishDetails
 */
export type GetRoomRoomidSectionSectionidStreamingPublishDetailsErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSectionSectionidStreamingKill
 */
export type PostRoomRoomidSectionSectionidStreamingKillErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSectionSectionidStreamingKeyRefresh
 */
export type PostRoomRoomidSectionSectionidStreamingKeyRefreshErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSectionSectionidStreamingTitle
 */
export type PostRoomRoomidSectionSectionidStreamingTitleErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidSectionSectionidStreamingConfig
 */
export type GetRoomRoomidSectionSectionidStreamingConfigErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSectionSectionidStreamingConfig
 */
export type PostRoomRoomidSectionSectionidStreamingConfigErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSectionSectionidStreamingThumb
 */
export type PostRoomRoomidSectionSectionidStreamingThumbErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_FILE
     */
    badRequestInvalidFile: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

/**
 * Form parameters for PostRoomRoomidSectionSectionidStreamingThumb
 */
export interface PostRoomRoomidSectionSectionidStreamingThumbFormParameters {
    /**
     * Image file to upload
     */
    file?: File;
}

/**
 * Error handler for DeleteRoomRoomidSectionSectionidStreamingThumb
 */
export type DeleteRoomRoomidSectionSectionidStreamingThumbErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

