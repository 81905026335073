// App preferences

import { AppEvents } from "./app-events";
import { LocalStorage } from "./local-storage";

export type ColorThemeName = "light" | "dark";

/**
 * Gets default theme, by checking the browser settings
 * @returns The theme name
 */
function defaultBrowserTheme(): ColorThemeName {
    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
    } else if (window.matchMedia) {
        return "light";
    } else {
        return "dark";
    }
}

/**
 * Cookies preferences value
 */
export type CookiesPreference = "all" | "essential" | "";


export class AppPreferences {
    public static Language = "en";
    public static Theme = "light";
    public static AdviceSave = false;
  
    public static AvailableLanguages = [
      {
        id: 'en',
        name: 'English'
      },
      {
        id: 'es',
        name: 'Español (Internacional)'
      },
    ];
  
    public static Currency = "USD";
  
    public static CookiesPreference: CookiesPreference = "";
  
    public static LoadPreferences() {
      const locale = (navigator.language || "en");
  
      AppPreferences.Language = LocalStorage.Get("app-pref-lang", locale);
      AppPreferences.Theme = LocalStorage.Get("app-pref-theme", defaultBrowserTheme());
      AppPreferences.Currency = LocalStorage.Get("app-pref-currency", "USD");
      AppPreferences.AdviceSave = LocalStorage.Get("app-advice-save", true);
      AppPreferences.CookiesPreference = LocalStorage.Get("app-cookies-pref", "");
    }
  
    public static SetLanguage(lang: string) {
      AppPreferences.Language = lang;
      LocalStorage.Set("app-pref-lang", lang);
    }
  
    public static SetTheme(t: string) {
      AppPreferences.Theme = t;
      LocalStorage.Set("app-pref-theme", t);
      AppEvents.Emit("theme-changed", t);
    }
  
    public static SetCurrency(t: string) {
      AppPreferences.Currency = t;
      LocalStorage.Set("app-pref-currency", t);
      AppEvents.Emit("currency-changed", t);
    }
  
    public static SetAdvicesSaveEnabled(t: boolean) {
      AppPreferences.AdviceSave = t;
      LocalStorage.Set("app-advice-save", t);
      AppEvents.Emit("advice-save-changed", t);
    }
  
    public static SetCookiesPreference(pref: CookiesPreference) {
      AppPreferences.CookiesPreference = pref;
      LocalStorage.Set("app-cookies-pref", pref);
    }
  }
