<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus">
        <div class="modal-dialog modal-lg" role="document" @click="stopPropagationEvent">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">{{ $t("Update streaming video") }}</div>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>{{ $t("Title") }}:</label>
                        <input type="text" maxlength="80" :disabled="busy" name="other-name" v-model="name" class="form-control" />
                    </div>
                    <div v-if="error" class="error-message">{{ error }}</div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary btn-mr" @click="confirm">
                        <i class="fas fa-check"></i> {{ $t("Save changes") }}
                    </button>
                    <button type="button" class="btn btn-cancel" @click="close">
                        {{ $t("Cancel") }}
                    </button>
                </div>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";

export default defineComponent({
    name: "UpdateStreamingVideoModal",
    emits: ["update:display", "confirm"],
    props: {
        display: Boolean,
        initName: String,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
        return {
            name: "",

            busy: false,
            error: "",
        };
    },
    methods: {
        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        confirm: function () {
            this.$emit("confirm", this.name, this.onSuccess.bind(this), this.onError.bind(this));
        },

        onError: function (msg) {
            this.busy = false;
            this.error = msg;
        },

        onSuccess: function () {
            this.busy = false;
            this.close();
        },
    },
    mounted: function () {
        this.name = this.initName;

        if (this.display) {
            this.$autoFocus();
        }
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$autoFocus();
            }
        },
        initName: function () {
            if (this.initName) {
                this.name = this.initName;
            }
        },
    },
});
</script>

<style></style>
