<template>
    <div
        class="custom-context-menu-container"
        :class="{ hidden: !display }"
        @mousedown="stopPropagationEvent"
        @mouseup="stopPropagationEvent"
        @touchstart="stopPropagationEvent"
        @click="clickOutside"
        tabindex="-1"
    >
        <div
            class="custom-context-menu"
            :style="{ top: top + 'px', left: left + 'px', 'max-width': mW, 'max-height': mH }"
            @mousedown="stopPropagationEvent"
            @mouseup="stopPropagationEvent"
            @touchstart="stopPropagationEvent"
            @click="stopPropagationEvent"
            tabindex="-1"
        >
            <div
                v-for="(t, index) in options"
                :key="index"
                :class="{
                    'custom-context-menu-item': !t.isGroup,
                    'custom-context-menu-group': !!t.isGroup,
                    'context-separator': !!t.separateDown,
                }"
                tabindex="0"
                @click="clickOption(t)"
            >
                <i v-if="!t.isGroup" :class="t.icon"></i> {{ t.caption }}
                <div v-if="!!t.isGroup" class="custom-context-menu-group-container">
                    <div
                        v-for="(c, index2) in t.children"
                        :key="index2"
                        class="custom-context-menu-group-item"
                        :title="c.caption"
                        tabindex="0"
                        @click="clickOption(c)"
                    >
                        <i :class="c.icon"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { FocusTrap } from "@/utils/focus-trap";
import { defineComponent, nextTick } from "vue";

export default defineComponent({
    components: {},
    name: "CustomContextMenu",
    setup: function () {
        return {
            focusTrap: null as FocusTrap,
        };
    },
    data: function () {
        return {
            options: [],
            display: false,

            top: 0,
            left: 0,

            mW: "",
            mH: "",
        };
    },
    methods: {
        show: function (top: number, left: number, options) {
            this.top = top;
            this.left = left;

            this.options = options;
            this.display = true;

            this.$el.querySelector(".custom-context-menu").focus();
            this.focusTrap.activate();

            nextTick(this.adjust.bind(this));
        },

        clickOutside: function(ev: Event) {
            ev.stopPropagation();
            ev.preventDefault();
            this.hide();
        },

        hide: function () {
            this.display = false;
            this.focusTrap.deactivate();
        },

        adjust: function () {
            const menuElement = this.$el.querySelector(".custom-context-menu");

            const maxW = window.innerWidth - 10;
            const maxH = window.innerHeight - 10;
            const w = menuElement.offsetWidth + 20;
            const h = menuElement.offsetHeight + 20;

            if (this.top + h > maxH) {
                this.top = this.top - h;

                if (this.top < 10 || this.top + h > maxH) {
                    this.top = Math.max(10, maxH - h);
                }
            }

            if (this.left + w > maxW) {
                this.left = this.left - w;

                if (this.left < 10 || this.left + w > maxW) {
                    this.left = Math.max(10, maxW - w);
                }
            }

            this.mW = maxW - this.left + "px";
            this.mH = maxH - this.top + "px";
        },

        clickOption: function (option) {
            if (option.isGroup) {
                return;
            }
            this.hide();
            option.callback();
        },

        stopPropagationEvent: function (ev: Event) {
            ev.stopPropagation();
        },
    },
    mounted: function () {
        this.focusTrap = new FocusTrap(this.$el, () => {
            this.hide();
        });
    },
    beforeUnmount: function () {
        if (this.focusTrap) {
            this.focusTrap.destroy();
        }
    },
});
</script>

<style>
.custom-context-menu-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: all;
}

.custom-context-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    border: solid 1px black;
    background: white;
    overflow: auto;
}

.custom-context-menu-container:focus,
.custom-context-menu:focus {
    outline: none;
}

.custom-context-menu-container.hidden {
    visibility: hidden;
}

.custom-context-menu-item {
    white-space: nowrap;
    cursor: pointer;
    font-weight: bold;
    padding: 1rem;
}

@media (max-width: 900px) {
    .custom-context-menu-item {
        padding: 0.5rem;
    }
}

.custom-context-menu-group {
    display: flex;
    flex-direction: column;
}

.custom-context-menu-group-container {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
}

.custom-context-menu-group-item {
    white-space: nowrap;
    cursor: pointer;
    font-weight: bold;
    padding: 1rem;
}

.custom-context-menu-item.context-separator:not(:last-child) {
    border-bottom: solid 1px black;
}

.custom-context-menu-item i {
    margin-right: 0.5rem;
}

.custom-context-menu-item:hover,
.custom-context-menu-group-item:hover,
.custom-context-menu-item:focus-visible,
.custom-context-menu-group-item:focus-visible {
    outline: none;
    background: #eeeeee;
}

.custom-context-menu-item:focus,
.custom-context-menu-group-item:focus {
    outline: none;
}

.dark-theme .custom-context-menu-item:hover,
.dark-theme .custom-context-menu-group-item:hover {
    background: #1b1b1b;
}

.dark-theme .custom-context-menu {
    border: solid 1px white;
    background: #212121;
}

.dark-theme .custom-context-menu-item.context-separator:not(:last-child) {
    border-bottom: solid 1px white;
}
</style>
