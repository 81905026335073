// API bindings: room (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { RoomList, SearchRoomPaginatedResponse, RoomListItem, JoinRoomStatus, NewRoomResponse, NewRoomRequest, RoomInfo, RoomConfiguration, RoomPictureResponse, RoomLink, SetOrderRequest, RoomLinkAddResponse, RoomLinkRequest, SectionIconResponse, RoomInfoByCode, SectionInfo, SectionUpdateRequest, SectionJWTInfo, SectionCreatedResponse, SectionCreateRequest, RoomTransferRequest, RoomCloneRequest, RoomQuotaResponse, SectionQuotaResponse, RoomSocials, RoomView, RoomShareInfo, RoomOptions } from "./definitions";

export class ApiRoom {
    /**
     * Method: GET
     * Path: /rooms/search
     * Search for public rooms in the platform
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetRoomsSearch(queryParams: GetRoomsSearchQueryParameters): RequestParams<RoomList, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/rooms/search` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /rooms/search/paginated
     * Search for public rooms in the platform (paginated)
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetRoomsSearchPaginated(queryParams: GetRoomsSearchPaginatedQueryParameters): RequestParams<SearchRoomPaginatedResponse, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/rooms/search/paginated` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /rooms/search/pinned
     * Search in the pinned rooms list for rooms you can administrate (select a room for references)
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetRoomsSearchPinned(queryParams: GetRoomsSearchPinnedQueryParameters): RequestParams<RoomList, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/rooms/search/pinned` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /rooms/templates
     * Gets room templates
     * @returns The request parameters
     */
    public static GetRoomsTemplates(): RequestParams<RoomListItem[], CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/rooms/templates`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /rooms/join/{room}
     * Joins a room using a code
     * @param room Room ID
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetRoomsJoinRoom(room: string, queryParams: GetRoomsJoinRoomQueryParameters): RequestParams<JoinRoomStatus, GetRoomsJoinRoomErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/rooms/join/${encodeURIComponent(room)}` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room
     * Add a new room
     * Only registered users can create rooms
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoom(body: NewRoomRequest): RequestParams<NewRoomResponse, PostRoomErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "TOO_MANY", handler.badRequestTooMany)
                    .add(400, "INVALID_TEMPLATE", handler.badRequestInvalidTemplate)
                    .add(400, "INVALID_DESCRIPTION", handler.badRequestInvalidDescription)
                    .add(400, "INVALID_NAME", handler.badRequestInvalidName)
                    .add(400, "INVALID_ORGANIZATION", handler.badRequestInvalidOrganization)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}
     * Get room info
     * @param roomId Room ID
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomid(roomId: string, headerParams: GetRoomRoomidHeaderParameters): RequestParams<RoomInfo, GetRoomRoomidErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}
     * Deletes a room
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomid(roomId: string): RequestParams<void, DeleteRoomRoomidErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/join/request
     * Requests moderators to join the room
     * You can request once each minute
     * You cannot request if you are banned
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static PostRoomRoomidJoinRequest(roomId: string): RequestParams<void, PostRoomRoomidJoinRequestErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/join/request`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "COOLDOWN", handler.forbiddenCooldown)
                    .add(403, "BANNED", handler.forbiddenBanned)
                    .add(403, "CANT_REQUEST", handler.forbiddenCantRequest)
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/config
     * Gets room configuration (ADMIN)
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static GetRoomRoomidConfig(roomId: string): RequestParams<RoomConfiguration, GetRoomRoomidConfigErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/config`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/config
     * Updates room configuration (ADMIN)
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidConfig(roomId: string, body: RoomConfiguration): RequestParams<void, PostRoomRoomidConfigErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/config`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_DESCRIPTION", handler.badRequestInvalidDescription)
                    .add(400, "INVALID_NAME", handler.badRequestInvalidName)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/picture
     * Uploads a new room image
     * @param roomId Room ID
     * @param formParams FromData parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidPicture(roomId: string, formParams: PostRoomRoomidPictureFormParameters): RequestParams<RoomPictureResponse, PostRoomRoomidPictureErrorHandler> {
        const formData = new FormData();

        formParams.file !== undefined && formData.append("file", formParams.file);

        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/picture`),
            form: formData,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/picture
     * Deletes room image
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidPicture(roomId: string): RequestParams<void, DeleteRoomRoomidPictureErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/picture`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/banner
     * Uploads a new room banner image
     * @param roomId Room ID
     * @param formParams FromData parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidBanner(roomId: string, formParams: PostRoomRoomidBannerFormParameters): RequestParams<RoomPictureResponse, PostRoomRoomidBannerErrorHandler> {
        const formData = new FormData();

        formParams.file !== undefined && formData.append("file", formParams.file);

        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/banner`),
            form: formData,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/banner
     * Deletes room banner image
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidBanner(roomId: string): RequestParams<void, DeleteRoomRoomidBannerErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/banner`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/logo
     * Uploads a new room logo image
     * @param roomId Room ID
     * @param formParams FromData parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidLogo(roomId: string, formParams: PostRoomRoomidLogoFormParameters): RequestParams<RoomPictureResponse, PostRoomRoomidLogoErrorHandler> {
        const formData = new FormData();

        formParams.file !== undefined && formData.append("file", formParams.file);

        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/logo`),
            form: formData,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/logo
     * Deletes room logo image
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidLogo(roomId: string): RequestParams<void, DeleteRoomRoomidLogoErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/logo`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/background
     * Uploads a new room background image
     * @param roomId Room ID
     * @param formParams FromData parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidBackground(roomId: string, formParams: PostRoomRoomidBackgroundFormParameters): RequestParams<RoomPictureResponse, PostRoomRoomidBackgroundErrorHandler> {
        const formData = new FormData();

        formParams.file !== undefined && formData.append("file", formParams.file);

        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/background`),
            form: formData,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/background
     * Deletes room background image
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidBackground(roomId: string): RequestParams<void, DeleteRoomRoomidBackgroundErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/background`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/links
     * Gets the list of room links
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static GetRoomRoomidLinks(roomId: string): RequestParams<RoomLink[], GetRoomRoomidLinksErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/links`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/links
     * Sets the order of room links
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidLinks(roomId: string, body: SetOrderRequest): RequestParams<void, PostRoomRoomidLinksErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/links`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/link
     * Adds a new link to the room
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidLink(roomId: string, body: RoomLinkRequest): RequestParams<RoomLinkAddResponse, PostRoomRoomidLinkErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/link`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "TOO_MANY", handler.badRequestTooMany)
                    .add(400, "INVALID_URL", handler.badRequestInvalidUrl)
                    .add(400, "INVALID_TITLE", handler.badRequestInvalidTitle)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/link/{linkId}
     * Updates room link
     * @param roomId Room ID
     * @param linkId Link ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidLinkLinkid(roomId: string, linkId: string, body: RoomLinkRequest): RequestParams<void, PostRoomRoomidLinkLinkidErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/link/${encodeURIComponent(linkId)}`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_URL", handler.badRequestInvalidUrl)
                    .add(400, "INVALID_TITLE", handler.badRequestInvalidTitle)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/link/{linkId}
     * Deletes room link
     * @param roomId Room ID
     * @param linkId Link ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidLinkLinkid(roomId: string, linkId: string): RequestParams<void, DeleteRoomRoomidLinkLinkidErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/link/${encodeURIComponent(linkId)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/link/{linkId}/icon
     * Uploads a new room link icon
     * @param roomId Room ID
     * @param linkId Link ID
     * @param formParams FromData parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidLinkLinkidIcon(roomId: string, linkId: string, formParams: PostRoomRoomidLinkLinkidIconFormParameters): RequestParams<SectionIconResponse, PostRoomRoomidLinkLinkidIconErrorHandler> {
        const formData = new FormData();

        formParams.file !== undefined && formData.append("file", formParams.file);

        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/link/${encodeURIComponent(linkId)}/icon`),
            form: formData,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/link/{linkId}/icon
     * Deletes room link icon
     * @param roomId Room ID
     * @param linkId Link ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidLinkLinkidIcon(roomId: string, linkId: string): RequestParams<void, DeleteRoomRoomidLinkLinkidIconErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/link/${encodeURIComponent(linkId)}/icon`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/access/{room}
     * Get room info by ID
     * @param room Room ID
     * @returns The request parameters
     */
    public static GetRoomAccessRoom(room: string): RequestParams<RoomInfoByCode, GetRoomAccessRoomErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/access/${encodeURIComponent(room)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}
     * Get section info
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionid(roomId: string, sectionId: string, headerParams: GetRoomRoomidSectionSectionidHeaderParameters): RequestParams<SectionInfo, GetRoomRoomidSectionSectionidErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}
     * Updates section info
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionid(roomId: string, sectionId: string, body: SectionUpdateRequest): RequestParams<void, PostRoomRoomidSectionSectionidErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_IFRAME_HTTPS_INVALID_PORT", handler.badRequestInvalidIframeHttpsInvalidPort)
                    .add(400, "INVALID_IFRAME_HTTPS_REQUIRED", handler.badRequestInvalidIframeHttpsRequired)
                    .add(400, "INVALID_IFRAME_X_FRAME_OPTIONS", handler.badRequestInvalidIframeXFrameOptions)
                    .add(400, "INVALID_IFRAME_NOT_HTML", handler.badRequestInvalidIframeNotHtml)
                    .add(400, "INVALID_IFRAME_REQUEST_ERROR", handler.badRequestInvalidIframeRequestError)
                    .add(400, "INVALID_URL", handler.badRequestInvalidUrl)
                    .add(400, "INVALID_TITLE", handler.badRequestInvalidTitle)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/section/{sectionId}
     * Deletes section
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidSectionSectionid(roomId: string, sectionId: string): RequestParams<void, DeleteRoomRoomidSectionSectionidErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}/jwt
     * Get section JWT
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionidJwt(roomId: string, sectionId: string, headerParams: GetRoomRoomidSectionSectionidJwtHeaderParameters): RequestParams<SectionJWTInfo, GetRoomRoomidSectionSectionidJwtErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/jwt`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/jwt
     * Updates section JWT config
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidJwt(roomId: string, sectionId: string, body: SectionJWTInfo): RequestParams<void, PostRoomRoomidSectionSectionidJwtErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/jwt`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_PARAM", handler.badRequestInvalidParam)
                    .add(400, "INVALID_SECRET", handler.badRequestInvalidSecret)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/visible
     * Makes section visible
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidVisible(roomId: string, sectionId: string): RequestParams<void, PostRoomRoomidSectionSectionidVisibleErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/visible`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/invisible
     * Makes section invisible
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidInvisible(roomId: string, sectionId: string): RequestParams<void, PostRoomRoomidSectionSectionidInvisibleErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/invisible`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/icon
     * Uploads a new section icon
     * @param roomId Room ID
     * @param sectionId Section ID
     * @param formParams FromData parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidIcon(roomId: string, sectionId: string, formParams: PostRoomRoomidSectionSectionidIconFormParameters): RequestParams<SectionIconResponse, PostRoomRoomidSectionSectionidIconErrorHandler> {
        const formData = new FormData();

        formParams.file !== undefined && formData.append("file", formParams.file);

        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/icon`),
            form: formData,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /room/{roomId}/section/{sectionId}/icon
     * Deletes section icon
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static DeleteRoomRoomidSectionSectionidIcon(roomId: string, sectionId: string): RequestParams<void, DeleteRoomRoomidSectionSectionidIconErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/icon`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/default-icon/activate
     * Activates the default secion icon
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidDefaulticonActivate(roomId: string, sectionId: string): RequestParams<void, PostRoomRoomidSectionSectionidDefaulticonActivateErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/default-icon/activate`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section/{sectionId}/default-icon/desactivate
     * Desactivates the default secion icon
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static PostRoomRoomidSectionSectionidDefaulticonDesactivate(roomId: string, sectionId: string): RequestParams<void, PostRoomRoomidSectionSectionidDefaulticonDesactivateErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/default-icon/desactivate`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/section
     * Add a new section
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidSection(roomId: string, body: SectionCreateRequest): RequestParams<SectionCreatedResponse, PostRoomRoomidSectionErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_IFRAME_X_FRAME_OPTIONS", handler.badRequestInvalidIframeXFrameOptions)
                    .add(400, "INVALID_IFRAME_NOT_HTML", handler.badRequestInvalidIframeNotHtml)
                    .add(400, "INVALID_IFRAME_REQUEST_ERROR", handler.badRequestInvalidIframeRequestError)
                    .add(400, "INVALID_IFRAME_HTTPS_INVALID_PORT", handler.badRequestInvalidIframeHttpsInvalidPort)
                    .add(400, "INVALID_IFRAME_HTTPS_REQUIRED", handler.badRequestInvalidIframeHttpsRequired)
                    .add(400, "TOO_MANY", handler.badRequestTooMany)
                    .add(400, "INVALID_TYPE", handler.badRequestInvalidType)
                    .add(400, "INVALID_URL", handler.badRequestInvalidUrl)
                    .add(400, "INVALID_TITLE", handler.badRequestInvalidTitle)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/sections
     * Sets the order of room sections for the left menu
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidSections(roomId: string, body: SetOrderRequest): RequestParams<void, PostRoomRoomidSectionsErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/sections`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/transfer
     * Transfers room ownership to another organization
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidTransfer(roomId: string, body: RoomTransferRequest): RequestParams<void, PostRoomRoomidTransferErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/transfer`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_ORGANIZATION", handler.badRequestInvalidOrganization)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/clone
     * Clones the room
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidClone(roomId: string, body: RoomCloneRequest): RequestParams<void, PostRoomRoomidCloneErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/clone`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "TOO_MANY", handler.badRequestTooMany)
                    .add(400, "INVALID_DESCRIPTION", handler.badRequestInvalidDescription)
                    .add(400, "INVALID_NAME", handler.badRequestInvalidName)
                    .add(400, "INVALID_ORGANIZATION", handler.badRequestInvalidOrganization)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/quota
     * Gets room quota (ADMIN)
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static GetRoomRoomidQuota(roomId: string): RequestParams<RoomQuotaResponse, GetRoomRoomidQuotaErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/quota`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/section/{sectionId}/quota
     * Gets section quota
     * @param roomId Room ID
     * @param sectionId Section ID
     * @returns The request parameters
     */
    public static GetRoomRoomidSectionSectionidQuota(roomId: string, sectionId: string): RequestParams<SectionQuotaResponse, GetRoomRoomidSectionSectionidQuotaErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/section/${encodeURIComponent(sectionId)}/quota`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/socials
     * Get room social networks
     * @param roomId Room ID
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidSocials(roomId: string, headerParams: GetRoomRoomidSocialsHeaderParameters): RequestParams<RoomSocials, GetRoomRoomidSocialsErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/socials`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/socials
     * Set social networks
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidSocials(roomId: string, body: RoomSocials): RequestParams<void, PostRoomRoomidSocialsErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/socials`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "BAD_INSTAGRAM", handler.badRequestBadInstagram)
                    .add(400, "BAD_FACEBOOK", handler.badRequestBadFacebook)
                    .add(400, "BAD_TWITTER", handler.badRequestBadTwitter)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/view
     * Gets room user visualization tyle
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static GetRoomRoomidView(roomId: string): RequestParams<RoomView, GetRoomRoomidViewErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/view`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/view
     * Updates room user view
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidView(roomId: string, body: RoomView): RequestParams<void, PostRoomRoomidViewErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/view`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/share
     * Get room sharing information
     * @param roomId Room ID
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetRoomRoomidShare(roomId: string, headerParams: GetRoomRoomidShareHeaderParameters): RequestParams<RoomShareInfo, GetRoomRoomidShareErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/share`),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/share
     * Change sharing configuration
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidShare(roomId: string, body: RoomShareInfo): RequestParams<void, PostRoomRoomidShareErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/share`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "BAD_SHARE_MODE", handler.badRequestBadShareMode)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /room/{roomId}/options
     * Get room options
     * @param roomId Room ID
     * @returns The request parameters
     */
    public static GetRoomRoomidOptions(roomId: string): RequestParams<RoomOptions, GetRoomRoomidOptionsErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/options`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /room/{roomId}/options
     * Change room options
     * @param roomId Room ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostRoomRoomidOptions(roomId: string, body: RoomOptions): RequestParams<RoomOptions, PostRoomRoomidOptionsErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/room/${encodeURIComponent(roomId)}/options`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Query parameters for GetRoomsSearch
 */
export interface GetRoomsSearchQueryParameters {
    /**
     * Search for
     */
    q?: string;

    /**
     * Set it to 1 to only search for title
     */
    titleonly?: string;
}

/**
 * Query parameters for GetRoomsSearchPaginated
 */
export interface GetRoomsSearchPaginatedQueryParameters {
    /**
     * Page. Starting by 1.
     */
    page?: number;
}

/**
 * Query parameters for GetRoomsSearchPinned
 */
export interface GetRoomsSearchPinnedQueryParameters {
    /**
     * Search for
     */
    q?: string;
}

/**
 * Error handler for GetRoomsJoinRoom
 */
export type GetRoomsJoinRoomErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Query parameters for GetRoomsJoinRoom
 */
export interface GetRoomsJoinRoomQueryParameters {
    /**
     * Code to join the room
     */
    code?: string;
}

/**
 * Error handler for PostRoom
 */
export type PostRoomErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_ORGANIZATION
     */
    badRequestInvalidOrganization: () => void;

    /**
     * Handler for status = 400 and code = INVALID_NAME
     */
    badRequestInvalidName: () => void;

    /**
     * Handler for status = 400 and code = INVALID_DESCRIPTION
     */
    badRequestInvalidDescription: () => void;

    /**
     * Handler for status = 400 and code = INVALID_TEMPLATE
     */
    badRequestInvalidTemplate: () => void;

    /**
     * Handler for status = 400 and code = TOO_MANY
     */
    badRequestTooMany: () => void;
};

/**
 * Error handler for GetRoomRoomid
 */
export type GetRoomRoomidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomid
 */
export interface GetRoomRoomidHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for DeleteRoomRoomid
 */
export type DeleteRoomRoomidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidJoinRequest
 */
export type PostRoomRoomidJoinRequestErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * Handler for status = 403 and code = CANT_REQUEST
     */
    forbiddenCantRequest: () => void;

    /**
     * Handler for status = 403 and code = BANNED
     */
    forbiddenBanned: () => void;

    /**
     * Handler for status = 403 and code = COOLDOWN
     */
    forbiddenCooldown: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidConfig
 */
export type GetRoomRoomidConfigErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidConfig
 */
export type PostRoomRoomidConfigErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_NAME
     */
    badRequestInvalidName: () => void;

    /**
     * Handler for status = 400 and code = INVALID_DESCRIPTION
     */
    badRequestInvalidDescription: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidPicture
 */
export type PostRoomRoomidPictureErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

/**
 * Form parameters for PostRoomRoomidPicture
 */
export interface PostRoomRoomidPictureFormParameters {
    /**
     * Image file to upload - Max 5MB
     */
    file?: File;
}

/**
 * Error handler for DeleteRoomRoomidPicture
 */
export type DeleteRoomRoomidPictureErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

/**
 * Error handler for PostRoomRoomidBanner
 */
export type PostRoomRoomidBannerErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

/**
 * Form parameters for PostRoomRoomidBanner
 */
export interface PostRoomRoomidBannerFormParameters {
    /**
     * Image file to upload
     */
    file?: File;
}

/**
 * Error handler for DeleteRoomRoomidBanner
 */
export type DeleteRoomRoomidBannerErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

/**
 * Error handler for PostRoomRoomidLogo
 */
export type PostRoomRoomidLogoErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

/**
 * Form parameters for PostRoomRoomidLogo
 */
export interface PostRoomRoomidLogoFormParameters {
    /**
     * Image file to upload
     */
    file?: File;
}

/**
 * Error handler for DeleteRoomRoomidLogo
 */
export type DeleteRoomRoomidLogoErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

/**
 * Error handler for PostRoomRoomidBackground
 */
export type PostRoomRoomidBackgroundErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

/**
 * Form parameters for PostRoomRoomidBackground
 */
export interface PostRoomRoomidBackgroundFormParameters {
    /**
     * Image file to upload
     */
    file?: File;
}

/**
 * Error handler for DeleteRoomRoomidBackground
 */
export type DeleteRoomRoomidBackgroundErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

/**
 * Error handler for GetRoomRoomidLinks
 */
export type GetRoomRoomidLinksErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidLinks
 */
export type PostRoomRoomidLinksErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidLink
 */
export type PostRoomRoomidLinkErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_TITLE
     */
    badRequestInvalidTitle: () => void;

    /**
     * Handler for status = 400 and code = INVALID_URL
     */
    badRequestInvalidUrl: () => void;

    /**
     * Handler for status = 400 and code = TOO_MANY
     */
    badRequestTooMany: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidLinkLinkid
 */
export type PostRoomRoomidLinkLinkidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_TITLE
     */
    badRequestInvalidTitle: () => void;

    /**
     * Handler for status = 400 and code = INVALID_URL
     */
    badRequestInvalidUrl: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for DeleteRoomRoomidLinkLinkid
 */
export type DeleteRoomRoomidLinkLinkidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidLinkLinkidIcon
 */
export type PostRoomRoomidLinkLinkidIconErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Form parameters for PostRoomRoomidLinkLinkidIcon
 */
export interface PostRoomRoomidLinkLinkidIconFormParameters {
    /**
     * Image file to upload
     */
    file?: File;
}

/**
 * Error handler for DeleteRoomRoomidLinkLinkidIcon
 */
export type DeleteRoomRoomidLinkLinkidIconErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomAccessRoom
 */
export type GetRoomAccessRoomErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidSectionSectionid
 */
export type GetRoomRoomidSectionSectionidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidSectionSectionid
 */
export interface GetRoomRoomidSectionSectionidHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for PostRoomRoomidSectionSectionid
 */
export type PostRoomRoomidSectionSectionidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_TITLE
     */
    badRequestInvalidTitle: () => void;

    /**
     * Handler for status = 400 and code = INVALID_URL
     */
    badRequestInvalidUrl: () => void;

    /**
     * Handler for status = 400 and code = INVALID_IFRAME_REQUEST_ERROR
     */
    badRequestInvalidIframeRequestError: () => void;

    /**
     * Handler for status = 400 and code = INVALID_IFRAME_NOT_HTML
     */
    badRequestInvalidIframeNotHtml: () => void;

    /**
     * Handler for status = 400 and code = INVALID_IFRAME_X_FRAME_OPTIONS
     */
    badRequestInvalidIframeXFrameOptions: () => void;

    /**
     * Handler for status = 400 and code = INVALID_IFRAME_HTTPS_REQUIRED
     */
    badRequestInvalidIframeHttpsRequired: () => void;

    /**
     * Handler for status = 400 and code = INVALID_IFRAME_HTTPS_INVALID_PORT
     */
    badRequestInvalidIframeHttpsInvalidPort: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for DeleteRoomRoomidSectionSectionid
 */
export type DeleteRoomRoomidSectionSectionidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidSectionSectionidJwt
 */
export type GetRoomRoomidSectionSectionidJwtErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidSectionSectionidJwt
 */
export interface GetRoomRoomidSectionSectionidJwtHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for PostRoomRoomidSectionSectionidJwt
 */
export type PostRoomRoomidSectionSectionidJwtErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_SECRET
     */
    badRequestInvalidSecret: () => void;

    /**
     * Handler for status = 400 and code = INVALID_PARAM
     */
    badRequestInvalidParam: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSectionSectionidVisible
 */
export type PostRoomRoomidSectionSectionidVisibleErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSectionSectionidInvisible
 */
export type PostRoomRoomidSectionSectionidInvisibleErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSectionSectionidIcon
 */
export type PostRoomRoomidSectionSectionidIconErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Form parameters for PostRoomRoomidSectionSectionidIcon
 */
export interface PostRoomRoomidSectionSectionidIconFormParameters {
    /**
     * Image file to upload
     */
    file?: File;
}

/**
 * Error handler for DeleteRoomRoomidSectionSectionidIcon
 */
export type DeleteRoomRoomidSectionSectionidIconErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSectionSectionidDefaulticonActivate
 */
export type PostRoomRoomidSectionSectionidDefaulticonActivateErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSectionSectionidDefaulticonDesactivate
 */
export type PostRoomRoomidSectionSectionidDefaulticonDesactivateErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSection
 */
export type PostRoomRoomidSectionErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_TITLE
     */
    badRequestInvalidTitle: () => void;

    /**
     * Handler for status = 400 and code = INVALID_URL
     */
    badRequestInvalidUrl: () => void;

    /**
     * Handler for status = 400 and code = INVALID_TYPE
     */
    badRequestInvalidType: () => void;

    /**
     * Handler for status = 400 and code = TOO_MANY
     */
    badRequestTooMany: () => void;

    /**
     * Handler for status = 400 and code = INVALID_IFRAME_HTTPS_REQUIRED
     */
    badRequestInvalidIframeHttpsRequired: () => void;

    /**
     * Handler for status = 400 and code = INVALID_IFRAME_HTTPS_INVALID_PORT
     */
    badRequestInvalidIframeHttpsInvalidPort: () => void;

    /**
     * Handler for status = 400 and code = INVALID_IFRAME_REQUEST_ERROR
     */
    badRequestInvalidIframeRequestError: () => void;

    /**
     * Handler for status = 400 and code = INVALID_IFRAME_NOT_HTML
     */
    badRequestInvalidIframeNotHtml: () => void;

    /**
     * Handler for status = 400 and code = INVALID_IFRAME_X_FRAME_OPTIONS
     */
    badRequestInvalidIframeXFrameOptions: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidSections
 */
export type PostRoomRoomidSectionsErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidTransfer
 */
export type PostRoomRoomidTransferErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_ORGANIZATION
     */
    badRequestInvalidOrganization: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidClone
 */
export type PostRoomRoomidCloneErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_ORGANIZATION
     */
    badRequestInvalidOrganization: () => void;

    /**
     * Handler for status = 400 and code = INVALID_NAME
     */
    badRequestInvalidName: () => void;

    /**
     * Handler for status = 400 and code = INVALID_DESCRIPTION
     */
    badRequestInvalidDescription: () => void;

    /**
     * Handler for status = 400 and code = TOO_MANY
     */
    badRequestTooMany: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidQuota
 */
export type GetRoomRoomidQuotaErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidSectionSectionidQuota
 */
export type GetRoomRoomidSectionSectionidQuotaErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidSocials
 */
export type GetRoomRoomidSocialsErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidSocials
 */
export interface GetRoomRoomidSocialsHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for PostRoomRoomidSocials
 */
export type PostRoomRoomidSocialsErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = BAD_TWITTER
     */
    badRequestBadTwitter: () => void;

    /**
     * Handler for status = 400 and code = BAD_FACEBOOK
     */
    badRequestBadFacebook: () => void;

    /**
     * Handler for status = 400 and code = BAD_INSTAGRAM
     */
    badRequestBadInstagram: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidView
 */
export type GetRoomRoomidViewErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidView
 */
export type PostRoomRoomidViewErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidShare
 */
export type GetRoomRoomidShareErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Header parameters for GetRoomRoomidShare
 */
export interface GetRoomRoomidShareHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for PostRoomRoomidShare
 */
export type PostRoomRoomidShareErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = BAD_SHARE_MODE
     */
    badRequestBadShareMode: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetRoomRoomidOptions
 */
export type GetRoomRoomidOptionsErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostRoomRoomidOptions
 */
export type PostRoomRoomidOptionsErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

